import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormDropDown from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormRadioGroup from '@/components/controls/CustomFormRadioGroup.tsx';
import { itemsForRadioGroup, sensDeviceTypes } from '@/utils/device.util.ts';

type SensDeviceSensorsFormProps = {
  form: UseFormReturn<any, any, any>;
};

const SensDeviceSensorsForm: FC<SensDeviceSensorsFormProps> = ({ form }) => {
  return (
    <>
      <CustomFormDropDown
        customForm={form}
        name="sensSensorType"
        labelText="Pod vrsta senzora SENTEK"
        formItemStyle="flex w-full flex-col max-w-[520px] mt-2 sm:mt-5 sm:pb-5 px-1"
        items={sensDeviceTypes}
        placeholder="Odaberite podvrstu senzora"
      />
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3 pb-3">
        <CustomFormRadioGroup
          customForm={form}
          name="humidityOne"
          labelText="Senzor vlažnosti zemljišta 1"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="humidityTwo"
          labelText="Senzor vlažnosti zemljišta 2"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2">
        <CustomFormRadioGroup
          customForm={form}
          name="soilTemperature"
          labelText="Temperatura zemljišta"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="soilSalinity"
          labelText="Salinitet zemljišta"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3">
        <CustomFormRadioGroup
          customForm={form}
          name="soilPH"
          labelText="Kiselost zemljišta pH"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="flowMeter"
          labelText="Protokomer"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3">
        <CustomFormRadioGroup
          customForm={form}
          name="airTemperature"
          labelText="Temperatura vazduha"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="airHumidity"
          labelText="Vlažnost vazduha"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3">
        <CustomFormRadioGroup
          customForm={form}
          name="windDirection"
          labelText="Smer vetra"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="windSpeed"
          labelText="Brzina vetra"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3">
        <CustomFormRadioGroup
          customForm={form}
          name="solarRadiation"
          labelText="Sunčevo zračenje"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="amountOfPrecipitation"
          labelText="Količina padavina"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3">
        <CustomFormRadioGroup
          customForm={form}
          name="batteryVoltage"
          labelText="Napon Baterije, Napon solarnog panela"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="notificationForWrongFlowMeter"
          labelText="Notifikacija za nedetektovanje protoka"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3 pb-5">
        <CustomFormRadioGroup
          customForm={form}
          name="pressureSensor"
          labelText="Senzor Pritiska u sistemu"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <div className="w-[50%]" />
      </div>
    </>
  );
};

export default SensDeviceSensorsForm;
