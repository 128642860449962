import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Checkbox } from '@/components/ui/checkbox.tsx';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form.tsx';

type CustomFormCheckboxProps = {
  form: UseFormReturn<any>;
  name: string;
  formItemStyle?: string;
  labelStyle?: string;
  labelText?: string;
  checkboxStyle?: string;
  isDisabled?: boolean;
};

const CustomFormCheckbox: FC<CustomFormCheckboxProps> = ({
  form,
  name,
  formItemStyle,
  labelStyle,
  labelText,
  checkboxStyle,
  isDisabled,
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className={labelStyle}>{labelText}</FormLabel>
          <FormControl>
            <Checkbox
              checked={field.value}
              onCheckedChange={field.onChange}
              className={checkboxStyle}
              disabled={isDisabled}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );
};

export default CustomFormCheckbox;
