import { FC } from 'react';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';

type FilterCustomSelectProps = {
  labelText: string;
  changeField: string;
  selectedValue: string;
  onFilterChange: (field: string, value: string) => void;
  filterValues: { value: string; label: string }[];
  placeholder?: string;
};

const FilterCustomSelect: FC<FilterCustomSelectProps> = ({
  labelText,
  changeField,
  selectedValue,
  onFilterChange,
  filterValues,
  placeholder,
}) => {
  return (
    <div className="grid items-center gap-1 w-full mt-2">
      <span>{labelText}</span>
      <Select
        value={selectedValue}
        onValueChange={(value: string) =>
          onFilterChange(changeField, value !== 'null' ? value : '')
        }
      >
        <SelectTrigger className="w-full">
          <SelectValue
            placeholder={placeholder}
            className="flex items-start text-start"
          />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem
              value="null"
              className="text-transparent focus:text-transparent"
            >
              Deselect
            </SelectItem>
            {filterValues.map((filterValue, idx) => (
              <SelectItem
                key={`user-country-${filterValue.value}-${idx + 1}`}
                value={filterValue.value}
              >
                {filterValue.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

export default FilterCustomSelect;
