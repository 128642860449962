import {
  NumberOfUsersByCountry,
  UserLastUsage,
  UserNumberOfUtilization,
  UserUtils,
} from '@/redux/statistics/statistics.types.ts';
import { RootState } from '@/redux/store.ts';
import { FetchUser } from '@/redux/users/users.types.ts';

export const getNumberOfUsersByCountry = (
  state: RootState
): NumberOfUsersByCountry[] =>
  state.reducer.statistics.numberOfUsersByCountry || [];

export const getUsersLastUsage = (state: RootState): UserLastUsage[] =>
  state.reducer.statistics.lastUsage || [];

export const getNumberOfUtilization = (
  state: RootState
): UserNumberOfUtilization[] =>
  state.reducer.statistics.numberOfUtilization || [];

export const getUserUtilization = (state: RootState): UserUtils[] =>
  state.reducer.statistics.userUtilization || [];

export const getNumberOfActiveParcels = (state: RootState): number =>
  state.reducer.statistics.numberOfActiveParcels || 0;

export const getNumberOfParcels = (state: RootState): number =>
  state.reducer.statistics.numberOfParcels || 0;

export const getNumberOfActiveSTARTs = (state: RootState): number =>
  state.reducer.statistics.STARTActive || 0;

export const getNumberOfActiveSENSs = (state: RootState): number =>
  state.reducer.statistics.SENSActive || 0;

export const getNumberOfActiveFERTs = (state: RootState): number =>
  state.reducer.statistics.FERTActive || 0;

export const getNumberOfNotActiveSTARTs = (state: RootState): number =>
  state.reducer.statistics.STARTNotActive || 0;

export const getNumberOfNotActiveSENSs = (state: RootState): number =>
  state.reducer.statistics.SENSNotActive || 0;

export const getNumberOfNotActiveFERTs = (state: RootState): number =>
  state.reducer.statistics.FERTNotActive || 0;

export const getNumberOfSubscribedUsers = (state: RootState): number =>
  state.reducer.statistics.subscribed || 0;

export const getNumberOfNotSubscribedUsers = (state: RootState): number =>
  state.reducer.statistics.notSubscribed || 0;

export const getNotSubscribedUsers = (state: RootState): FetchUser[] =>
  state.reducer.statistics.users || [];
