import { FC } from 'react';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import DistributorTableRow from '@/components/layout/distributors/DistributorTableRow.tsx';
import PaginationSection from '@/components/layout/PaginationSection.tsx';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { Table, TableBody } from '@/components/ui/table.tsx';
import {
  getDistributors,
  getFilteredNumberOfDistributors,
  getTotalNumberOfDistributors,
} from '@/redux/distributors/distributors.selectors.ts';
import { useAppSelector } from '@/redux/hooks.ts';

const distributorsTableHeaders: TableHeaderType[] = [
  {
    id: 'name',
    text: 'Distributer',
    className: 'max-sm:hidden',
  },
  {
    id: 'place',
    text: 'Mesto',
    className: 'max-sm:hidden',
  },
  { id: 'email', text: 'Email', className: '' },
  { id: 'phoneNumber', text: 'Telefon', className: 'max-lg:hidden' },
  { id: 'number_of_users', text: 'Broj korisnika', className: 'max-lg:hidden' },
  { id: 'createdAt', text: 'Datum kreiranja', className: 'max-xl:hidden' },
  {
    id: 'actions',
    text: '',
    className: '',
    isSortDisabled: true,
  },
];

type DistributorsTableSectionProps = {
  page: number;
  rowsPerPage: number;
  onColumnClick: (column: string) => void;
  onPageChangeClick: (selectedPage: number) => void;
  onDistributorDelete: (distributorId: string) => void;
  onRowsPerPageChange: (selectedRowsPerPage: number) => void;
};

const DistributorsTableSection: FC<DistributorsTableSectionProps> = ({
  page,
  rowsPerPage,
  onColumnClick,
  onPageChangeClick,
  onDistributorDelete,
  onRowsPerPageChange,
}) => {
  const distributors = useAppSelector(getDistributors);
  const totalNumberOfDistributors = useAppSelector(
    getTotalNumberOfDistributors
  );
  const filteredNumberOfDistributors = useAppSelector(
    getFilteredNumberOfDistributors
  );

  return (
    <Card x-chunk="dashboard-06-chunk-0">
      <CardHeader>
        <CardTitle>Distributeri</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <CustomTableHeader
            tableName="distributors"
            tableHeaders={distributorsTableHeaders}
            onHeaderCellClick={onColumnClick}
          />
          <TableBody>
            {distributors.map((distributor, index) => (
              <DistributorTableRow
                key={`distributor-table-data-user-${index + 1}`}
                distributor={distributor}
                onDeleteDistributor={onDistributorDelete}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <PaginationSection
          page={page}
          rowsPerPage={rowsPerPage}
          filteredNumber={filteredNumberOfDistributors}
          totalNumber={totalNumberOfDistributors}
          onPageChangeClick={onPageChangeClick}
          entity="distributera"
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </CardFooter>
    </Card>
  );
};

export default DistributorsTableSection;
