import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pie, PieChart } from 'recharts';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getCountDevices } from '@/redux/statistics/statistics.actions.ts';
import {
  getNumberOfActiveFERTs,
  getNumberOfActiveSENSs,
  getNumberOfActiveSTARTs,
  getNumberOfNotActiveFERTs,
  getNumberOfNotActiveSENSs,
  getNumberOfNotActiveSTARTs,
} from '@/redux/statistics/statistics.selectors.ts';
import { GetCountOfDevices } from '@/redux/statistics/statistics.types.ts';

const chartConfig = {
  devices: {
    label: 'Uredjaji',
  },
  SENS: {
    label: 'SENS',
    color: 'hsl(var(--chart-1))',
  },
  START: {
    label: 'START',
    color: 'hsl(var(--chart-2))',
  },
  FERT: {
    label: 'FERT',
    color: 'hsl(var(--chart-3))',
  },
} satisfies ChartConfig;

const DeviceCountStatistic: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const numberOfActiveFERTs = useAppSelector(getNumberOfActiveFERTs);
  const numberOfActiveSTARTs = useAppSelector(getNumberOfActiveSTARTs);
  const numberOfActiveSENSs = useAppSelector(getNumberOfActiveSENSs);
  const numberOfNotActiveFERTs = useAppSelector(getNumberOfNotActiveFERTs);
  const numberOfNotActiveSTARTs = useAppSelector(getNumberOfNotActiveSTARTs);
  const numberOfNotActiveSENSs = useAppSelector(getNumberOfNotActiveSENSs);
  const activeChartData = [
    { name: 'FERT', value: numberOfActiveFERTs, fill: '#39a6c9' },
    { name: 'START', value: numberOfActiveSTARTs, fill: '#ecf263' },
    { name: 'SENS', value: numberOfActiveSENSs, fill: '#0dcd8e' },
  ];

  const notActiveChartData = [
    { name: 'FERT', value: numberOfNotActiveFERTs, fill: '#39a6c9' },
    { name: 'START', value: numberOfNotActiveSTARTs, fill: '#ecf263' },
    { name: 'SENS', value: numberOfNotActiveSENSs, fill: '#0dcd8e' },
  ];

  const handleErrorResponse = (response: GetCountOfDevices) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchNumberOfDevices = async () => {
      // @ts-ignore
      const response = await dispatch(getCountDevices()).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchNumberOfDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Statistika uredjaja</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-row gap-5 max-md:flex-col">
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Broj aktivnih uredjaja</CardTitle>
              </CardHeader>
              <CardContent>
                <ChartContainer config={chartConfig}>
                  <PieChart>
                    <Pie
                      data={activeChartData}
                      dataKey="value"
                      nameKey="name"
                    />
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <ChartLegend
                      content={<ChartLegendContent nameKey="name" />}
                      className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
                    />
                  </PieChart>
                </ChartContainer>
              </CardContent>
            </Card>
          </div>
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Broj neaktivnih uredjaja</CardTitle>
              </CardHeader>
              <CardContent>
                <ChartContainer config={chartConfig}>
                  <PieChart>
                    <Pie
                      data={notActiveChartData}
                      dataKey="value"
                      nameKey="name"
                    />
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <ChartLegend
                      content={<ChartLegendContent nameKey="name" />}
                      className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center"
                    />
                  </PieChart>
                </ChartContainer>
              </CardContent>
            </Card>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
export default DeviceCountStatistic;
