import { z } from 'zod';

export const createUserSchema = z
  .object({
    name: z.string().min(1, {
      message: 'Ime je obavezno polje',
    }),
    surname: z.string().min(1, {
      message: 'Prezime je obavezno polje',
    }),
    email: z.string().email({
      message: 'Email nije validan',
    }),
    password: z.string().min(1, {
      message: 'Lozinka je obavezno polje',
    }),
    phoneNumber: z.string().min(1, {
      message: 'Telefon je obavezno polje',
    }),
    address: z.string().min(1, {
      message: 'Adresa je obavezno polje',
    }),
    place: z.string().min(1, {
      message: 'Mesto je obavezno polje',
    }),
    country: z.string().min(1, {
      message: 'Drzava je obavezno polje',
    }),
    nameOfTheCompany: z.string().optional(),
    pib: z.string().optional(),
    mbr: z.string().optional(),
    dateOfPayment: z.date({
      required_error: 'Datum plaćanja pretplate je obavezan',
    }),
    differentBillingAddress: z.boolean(),
    billingAddress: z.string().optional(),
    billingPlace: z.string().optional(),
    billingCountry: z.string().optional(),
    wayOfInforming: z.string().min(1, {
      message: 'Niste odabrali način informisanja',
    }),
    organizationId: z.string().optional(),
    zip: z.string().min(1, {
      message: 'Poštanski broj je obavezno polje',
    }),
    language: z.string().min(1, {
      message: 'Jezik na kome korisnik koristi aplikaciju je obavezno polje',
    }),
  })
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingAddress?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Adresa za naplatu je obavezno polje',
      path: ['billingAddress'],
    }
  )
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingPlace?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Mesto za naplatu je obavezno polje',
      path: ['billingPlace'],
    }
  )
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingCountry?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Država za naplatu je obavezno polje',
      path: ['billingCountry'],
    }
  );

export const editUserSchema = z
  .object({
    id: z.string().min(1),
    name: z.string().min(1, {
      message: 'Ime je obavezno polje',
    }),
    surname: z.string().min(1, {
      message: 'Prezime je obavezno polje',
    }),
    email: z.string().email({
      message: 'Email nije validan',
    }),
    phoneNumber: z.string().min(1, {
      message: 'Telefon je obavezno polje',
    }),
    address: z.string().min(1, {
      message: 'Adresa je obavezno polje',
    }),
    place: z.string().min(1, {
      message: 'Mesto je obavezno polje',
    }),
    country: z.string().min(1, {
      message: 'Drzava je obavezno polje',
    }),
    nameOfTheCompany: z.string().optional(),
    pib: z.string().optional(),
    mbr: z.string().optional(),
    dateOfPayment: z.date({
      required_error: 'Datum plaćanja pretplate je obavezan',
    }),
    differentBillingAddress: z.boolean(),
    billingAddress: z.string().optional(),
    billingPlace: z.string().optional(),
    billingCountry: z.string().optional(),
    wayOfInforming: z.string().min(1, {
      message: 'Niste odabrali način informisanja',
    }),
    organizationId: z.string().optional(),
    zip: z.string().min(1, {
      message: 'Poštanski broj je obavezno polje',
    }),
    language: z.string().min(1, {
      message: 'Jezik na kome korisnik koristi aplikaciju je obavezno polje',
    }),
  })
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingAddress?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Adresa za naplatu je obavezno polje',
      path: ['billingAddress'],
    }
  )
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingPlace?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Mesto za naplatu je obavezno polje',
      path: ['billingPlace'],
    }
  )
  .refine(
    (data) => {
      if (data.differentBillingAddress) {
        return data.billingCountry?.trim().length > 0;
      }
      return true;
    },
    {
      message: 'Država za naplatu je obavezno polje',
      path: ['billingCountry'],
    }
  );

export const loginSchema = z.object({
  username: z.string().email({
    message: 'Email nije validan',
  }),
  password: z.string().min(1, {
    message: 'Lozinka je obavezno polje',
  }),
});

export const forgotPasswordSchema = z.object({
  email: z.string().email({
    message: 'Email nije validan',
  }),
});

export const resetPasswordSchema = z
  .object({
    token: z.string().min(1, 'Token je obaezan'),
    password: z
      .string()
      .min(8, 'Lozinka mora imati najmanje 8 karaktera')
      .max(100, 'Lozinka ne moze biti duza od 100 karaktera')
      .refine(
        (value) => /[a-z]/.test(value),
        'Lozinka mora sadrzati najmanje 1 malo slovo'
      )
      .refine(
        (value) => /[A-Z]/.test(value),
        'Lozinka mora sadrzati najmanje 1 veliko slovo'
      )
      .refine(
        (value) => /\d/.test(value),
        'Lozinka mora sadrzati najmanje jednu cifru'
      )
      .refine(
        (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value),
        'Lozinka mora sadrzati najmanje 1 specijalni karakter'
      ),
    confirmPassword: z.string().min(1, 'Potvrda lozinke je obavezna'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Lozinke se razlikuju',
    path: ['confirmPassword'],
  });
