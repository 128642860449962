import { FC } from 'react';

import { createPaginationSection } from '@/components/layout/pagination/Pagination.tsx';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';

const rowsPerPageOptions = [
  {
    label: '10',
    value: '10',
  },
  {
    label: '50',
    value: '50',
  },
  {
    label: '100',
    value: '100',
  },
];

type PaginationRowsPerPageSelectionProps = {
  rowsPerPage: number;
  onRowsPerPageChange: (selectedRows: number) => void;
};

type PaginationSectionProps = {
  page: number;
  rowsPerPage: number;
  filteredNumber: number;
  totalNumber: number;
  onPageChangeClick: (selectedPage: number) => void;
  entity: string;
  onRowsPerPageChange: (selectedRows: number) => void;
};

const PaginationRowsPerPageSelection: FC<
  PaginationRowsPerPageSelectionProps
> = ({ rowsPerPage, onRowsPerPageChange }) => {
  return (
    <div className="flex flex-row items-center gap-2 mt-5">
      <p>Rezultata po stranici:</p>
      <Select
        value={rowsPerPage.toString()}
        onValueChange={(value: string) => onRowsPerPageChange(Number(value))}
      >
        <SelectTrigger className="w-[70px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {rowsPerPageOptions.map((filterValue, idx) => (
              <SelectItem
                key={`rows-per-page-${filterValue.value}-${idx + 1}`}
                value={filterValue.value}
              >
                {filterValue.label}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};

const PaginationSection: FC<PaginationSectionProps> = ({
  page,
  rowsPerPage,
  filteredNumber,
  totalNumber,
  onPageChangeClick,
  entity,
  onRowsPerPageChange,
}) => {
  const getTextForPagination = () => {
    if (filteredNumber === 0) {
      return `Prikaz 0 do 0 od ukupno 0 ${entity}`;
    }

    const text = `Prikaz ${page * rowsPerPage + 1} do ${filteredNumber > rowsPerPage ? rowsPerPage * page + rowsPerPage : filteredNumber} od ukupno ${filteredNumber} ${entity}`;

    if (filteredNumber !== totalNumber) {
      return `${text} (filtrirano od ukupno ${totalNumber} ${entity}) `;
    }

    return text;
  };

  return (
    <div className="flex flex-row max-sm:flex-col justify-between items-center w-full px-5 max-sm:gap-3 mt-4">
      <div className="text-xs text-muted-foreground">
        <p>{getTextForPagination()}</p>
        <PaginationRowsPerPageSelection
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </div>
      <div>
        {createPaginationSection(
          rowsPerPage,
          filteredNumber,
          page,
          onPageChangeClick
        )}
      </div>
    </div>
  );
};

export default PaginationSection;
