import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { BreadcrumbLinkType } from '@/redux/breadcrumbs/breadcrumbs.types.ts';
import { useAppSelector } from '@/redux/hooks.ts';

type BreadcrumbDropdownProps = {
  breadcrumbsList: BreadcrumbLinkType[];
  maxVisibleLinks: number;
};

type BreadcrumbLinkWrapperProps = {
  breadcrumbLink: BreadcrumbLinkType;
};

const BreadcrumbLinkWrapper: FC<BreadcrumbLinkWrapperProps> = ({
  breadcrumbLink,
}) => {
  return (
    <>
      <BreadcrumbItem>
        {breadcrumbLink.showSeparator ? (
          <BreadcrumbLink asChild>
            <Link to={breadcrumbLink.link} className="text-lg">
              {breadcrumbLink.linkText}
            </Link>
          </BreadcrumbLink>
        ) : (
          <BreadcrumbPage className="text-lg">
            {breadcrumbLink.linkText}
          </BreadcrumbPage>
        )}
      </BreadcrumbItem>
      {breadcrumbLink.showSeparator ? <BreadcrumbSeparator /> : null}
    </>
  );
};

const BreadcrumbDropdown: FC<BreadcrumbDropdownProps> = ({
  breadcrumbsList,
  maxVisibleLinks,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <BreadcrumbItem>
        <DropdownMenu open={open} onOpenChange={setOpen}>
          <DropdownMenuTrigger
            className="flex items-center gap-1"
            aria-label="Toggle menu"
          >
            <BreadcrumbEllipsis className="h-4 w-4" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            {breadcrumbsList
              .slice(1, -1 * (maxVisibleLinks - 1))
              .map((item, index) => (
                <DropdownMenuItem key={`drop-down-link-${index + 1}`}>
                  <Link to={item.link}>{item.linkText}</Link>
                </DropdownMenuItem>
              ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </BreadcrumbItem>
      <BreadcrumbSeparator />
    </>
  );
};

const BreadcrumbWrapper: FC = () => {
  const breadcrumbsList = useAppSelector(getBreadcrumbs);
  const breadcrumbRef = useRef<HTMLDivElement | null>(null);
  const [maxVisibleLinks, setMaxVisibleLinks] = useState(
    breadcrumbsList.length
  );

  const calculateMaxVisible = () => {
    if (!breadcrumbRef.current) return breadcrumbsList.length;

    const containerWidth = breadcrumbRef.current.offsetWidth;
    const itemWidth = 170;
    return Math.floor(containerWidth / itemWidth);
  };

  const updateVisibleBreadcrumbs = () => {
    const maxValue = calculateMaxVisible();
    if (maxValue <= 2) {
      setMaxVisibleLinks(2);
      return;
    }
    setMaxVisibleLinks(calculateMaxVisible());
  };

  useEffect(() => {
    updateVisibleBreadcrumbs();
    window.addEventListener('resize', updateVisibleBreadcrumbs);
    return () => {
      window.removeEventListener('resize', updateVisibleBreadcrumbs);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadcrumbsList]);

  return (
    <Breadcrumb ref={breadcrumbRef} className="hidden sm:flex md:z-10 w-full">
      <BreadcrumbList>
        <BreadcrumbLinkWrapper breadcrumbLink={breadcrumbsList[0]} />
        {breadcrumbsList.length > maxVisibleLinks ? (
          <BreadcrumbDropdown
            breadcrumbsList={breadcrumbsList}
            maxVisibleLinks={maxVisibleLinks}
          />
        ) : null}
        {breadcrumbsList.length !== 1
          ? breadcrumbsList
              .slice(
                -1 * (Math.min(maxVisibleLinks, breadcrumbsList.length) - 1)
              )
              .map((item, index) => (
                <BreadcrumbLinkWrapper
                  breadcrumbLink={item}
                  key={`breadcrumb-link-${index + 1}`}
                />
              ))
          : null}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export default BreadcrumbWrapper;
