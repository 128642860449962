import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CreateParcelBoundaryForm from '@/components/forms/parcels/CreateParcelBoundaryForm.tsx';
import CreateParcelInfoForm from '@/components/forms/parcels/CreateParcelInfoForm.tsx';
import CreateParcelSensorForm from '@/components/forms/parcels/CreateParcelSensorForm.tsx';
import EditParcelAdditionalSensorForm from '@/components/forms/parcels/EditParcelAdditionalSensorForm.tsx';
import EditParcelSettingsForm from '@/components/forms/parcels/EditParcelSettingsForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { updateParcel } from '@/redux/parcels/parcels.actions.ts';
import { CreateParcelResponse, Parcel } from '@/redux/parcels/parcels.types.ts';
import {
  castStringToNumber,
  createEditParcelStartData,
} from '@/utils/parcels.ts';
import { editParcelSchema } from '@/zod/parcel.types.zod.ts';

export type EditParcelFormData = {
  id: string;
  name: string;
  numberOfZones: number;
  maxBetweenTwoShifts: string;
  place: string;
  country: string;
  weatherForecast: string;
  simCardNumber: string;
  boundaryForDryLand: number;
  boundaryForWetlands: number;
  humiditySensor: boolean;
  secondHumiditySensor: boolean;
  supplementMixer: boolean;
  supplement: boolean;
  voltage: boolean;
  rawSensorValues: boolean;
  coordinates: string;
  numberOfIrrigation: boolean;
  airHumidity: boolean;
  pumpSensor: boolean;
  numberOfZonesSwitch: boolean;
  airTemperature: boolean;
  soilTemperatureSensor: boolean;
  soilSalinity: boolean;
  flowMeter: boolean;
  waterLevelSensor: boolean;
  pHSensor: boolean;
  flowMeterCapacity: string;
  flowMeterFactorK: string;
  flowMeterFactorM: string;
  pHFactorK: string;
  minHumidityForAutomaticIrrigation: string;
  optimalHumidityForAutomaticIrrigation: string;
  durationOfTheActiveValve: string;
  timeBetweenValves: string;
  moreSENSSensors: boolean;
  organizationId: string;
};

type EditParcelFormProps = {
  existingParcel: Parcel;
};

const EditParcelForm: FC<EditParcelFormProps> = ({ existingParcel }) => {
  const editForm = useForm({
    resolver: zodResolver(editParcelSchema),
    defaultValues: { ...createEditParcelStartData() },
  });
  const [activeAccordion, setActiveAccordion] = useState<string>('infoForm');
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const breadcrumbs = useAppSelector(getBreadcrumbs);

  const handleErrorResponse = (response: CreateParcelResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const onSubmit = async (data: EditParcelFormData) => {
    // @ts-ignore
    const response = await dispatch(updateParcel(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Parcela je uspešno izmenjena');
    onGiveUpClick();
  };

  const setEditFormBasicSensorValues = () => {
    editForm.setValue(
      'rawSensorValues',
      existingParcel.basicSensorsValues.rawSensorValues
    );
    editForm.setValue(
      'humiditySensor',
      existingParcel.basicSensorsValues.humiditySensor
    );
    editForm.setValue(
      'secondHumiditySensor',
      existingParcel.basicSensorsValues.secondHumiditySensor
    );
    editForm.setValue('voltage', existingParcel.basicSensorsValues.voltage);
    editForm.setValue(
      'supplement',
      existingParcel.basicSensorsValues.supplement
    );
    editForm.setValue(
      'supplementMixer',
      existingParcel.basicSensorsValues.supplementMixer
    );
  };

  const setEditFormAdditionalSensorValues = () => {
    editForm.setValue(
      'pHSensor',
      existingParcel.additionalSensorsValues.pHSensor
    );
    editForm.setValue(
      'pumpSensor',
      existingParcel.additionalSensorsValues.pumpSensor
    );
    editForm.setValue(
      'waterLevelSensor',
      existingParcel.additionalSensorsValues.waterLevelSensor
    );
    editForm.setValue(
      'moreSENSSensors',
      existingParcel.additionalSensorsValues.moreSENSSensors
    );
    editForm.setValue(
      'soilTemperatureSensor',
      existingParcel.additionalSensorsValues.soilTemperatureSensor
    );
    editForm.setValue(
      'airHumidity',
      existingParcel.additionalSensorsValues.airHumidity
    );
    editForm.setValue(
      'flowMeter',
      existingParcel.additionalSensorsValues.flowMeter
    );
    editForm.setValue(
      'timeBetweenValves',
      existingParcel.additionalSensorsValues.timeBetweenValves.toString()
    );
    editForm.setValue(
      'soilSalinity',
      existingParcel.additionalSensorsValues.soilSalinity
    );
    editForm.setValue(
      'optimalHumidityForAutomaticIrrigation',
      existingParcel.additionalSensorsValues.optimalHumidityForAutomaticIrrigation.toString()
    );
    editForm.setValue(
      'durationOfTheActiveValve',
      existingParcel.additionalSensorsValues.durationOfTheActiveValve.toString()
    );
    editForm.setValue(
      'pHFactorK',
      existingParcel.additionalSensorsValues.pHFactorK.toString()
    );
    editForm.setValue(
      'flowMeterFactorM',
      existingParcel.additionalSensorsValues.flowMeterFactorM.toString()
    );
    editForm.setValue(
      'flowMeterCapacity',
      existingParcel.additionalSensorsValues.flowMeterCapacity.toString()
    );
    editForm.setValue(
      'numberOfIrrigation',
      existingParcel.additionalSensorsValues.numberOfIrrigation
    );
    editForm.setValue(
      'numberOfZonesSwitch',
      existingParcel.additionalSensorsValues.numberOfZones
    );
    editForm.setValue(
      'airTemperature',
      existingParcel.additionalSensorsValues.airTemperature
    );
    editForm.setValue(
      'flowMeterFactorK',
      existingParcel.additionalSensorsValues.flowMeterFactorK.toString()
    );
    editForm.setValue(
      'minHumidityForAutomaticIrrigation',
      existingParcel.additionalSensorsValues.minHumidityForAutomaticIrrigation.toString()
    );
  };

  useEffect(() => {
    editForm.setValue('id', existingParcel.id);
    editForm.setValue('name', existingParcel.name);
    editForm.setValue('boundaryForDryLand', existingParcel.boundaryForDryLand);
    editForm.setValue(
      'boundaryForWetlands',
      existingParcel.boundaryForWetlands
    );
    editForm.setValue(
      'coordinates',
      existingParcel.coordinates ? existingParcel.coordinates : ''
    );
    editForm.setValue('place', existingParcel.place);
    editForm.setValue('country', existingParcel.country);
    editForm.setValue(
      'maxBetweenTwoShifts',
      existingParcel.maxBetweenTwoShifts.toString()
    );
    editForm.setValue('numberOfZones', existingParcel.numberOfZones);
    editForm.setValue('simCardNumber', existingParcel.simCardNumber);
    editForm.setValue('weatherForecast', existingParcel.weatherForecast);
    editForm.setValue('organizationId', existingParcel.organizationId);
    setEditFormBasicSensorValues();
    setEditFormAdditionalSensorValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingParcel]);

  const handleErrorAccordion = () => {
    const { errors } = editForm.formState;

    if (Object.keys(errors).length === 0) {
      return;
    }

    if (
      errors.name ||
      errors.organizationId ||
      errors.numberOfZones ||
      errors.place ||
      errors.country ||
      errors.weatherForecast ||
      errors.simCardNumber
    ) {
      setActiveAccordion('infoForm');

      return;
    }

    if (errors.boundaryForDryLand || errors.boundaryForWetlands) {
      setActiveAccordion('boundariesForm');

      return;
    }

    setActiveAccordion('settingsForm');
  };

  const handleFormSubmit = async () => {
    castStringToNumber(editForm);

    const isValid = await editForm.trigger();
    if (!isValid) {
      handleErrorAccordion();
      return;
    }

    editForm.handleSubmit(onSubmit)();
  };

  return (
    <Form
      reset={editForm.reset}
      formState={editForm.formState}
      clearErrors={editForm.clearErrors}
      control={editForm.control}
      getFieldState={editForm.getFieldState}
      getValues={editForm.getValues}
      handleSubmit={editForm.handleSubmit}
      register={editForm.register}
      resetField={editForm.resetField}
      setError={editForm.setError}
      setFocus={editForm.setFocus}
      setValue={editForm.setValue}
      trigger={editForm.trigger}
      unregister={editForm.unregister}
      watch={editForm.watch}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <Accordion
          value={activeAccordion}
          onValueChange={(value) => setActiveAccordion(value || '')}
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovne informacije o parceli</AccordionTrigger>
            <AccordionContent>
              <CreateParcelInfoForm form={editForm} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sensors">
            <AccordionTrigger>Senzori</AccordionTrigger>
            <AccordionContent>
              <CreateParcelSensorForm form={editForm} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="boundariesForm">
            <AccordionTrigger>Granice za zemljište</AccordionTrigger>
            <AccordionContent>
              <CreateParcelBoundaryForm form={editForm} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="additionalSensorsForm">
            <AccordionTrigger>Dodatni senzori</AccordionTrigger>
            <AccordionContent>
              <EditParcelAdditionalSensorForm form={editForm} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="settingsForm">
            <AccordionTrigger>Podešavanja</AccordionTrigger>
            <AccordionContent>
              <EditParcelSettingsForm form={editForm} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Izmeni parcelu"
        />
      </form>
    </Form>
  );
};

export default EditParcelForm;
