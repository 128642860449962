import { DID, UpdateDid } from '@/redux/did/did.types.ts';
import { RootState } from '@/redux/store.ts';

export const getDids = (state: RootState): DID[] =>
  state.reducer.dids.dids || [];

export const updatedDid = (state: RootState): UpdateDid =>
  state.reducer.dids.updatedDid;

export const getOccupiedDidsWithoutDevice = (state: RootState): DID[] =>
  state.reducer.dids.occupiedDidsWithoutDevice || [];

export const getDidByDeviceId = (state: RootState): DID =>
  state.reducer.dids.didByDeviceId;
