import axios from 'axios';

import { config } from '../config/config.ts';

import { getFromLocalStorage } from './local.storage.ts';

const axiosServer = axios.create({
  baseURL: config.backend.apiUrl,
  withCredentials: true,
});

const handleUnauthorizedResponse = async () => {
  return {
    data: { success: false, error: { message: 'Error', removeUser: true } },
  };
};

axiosServer.interceptors.request.use(
  (reqConfig) => {
    // eslint-disable-next-line no-param-reassign
    reqConfig.headers.token = getFromLocalStorage('token');

    return reqConfig;
  },
  (error) => {
    return error;
  }
);

axiosServer.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      return handleUnauthorizedResponse();
    }

    return error.response;
  }
);

export default axiosServer;
