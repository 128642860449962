import { FC } from 'react';

import DeliverySENSDeviceForm from '@/components/forms/delivery/DeliverySENSDeviceForm.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx';
import { DeviceRevision, SensDevice } from '@/redux/device/devices.types.ts';

type SENSDevicesTableProps = {
  userSENSDevices: SensDevice[];
  revisionsSENS: DeviceRevision[];
};

const SENSDevicesTable: FC<SENSDevicesTableProps> = ({
  userSENSDevices,
  revisionsSENS,
}) => {
  return (
    <Card x-chunk="user-fert-devices">
      <CardHeader>
        <CardTitle className="flex items-center justify-between w-full py-3">
          SENS uređaji
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="overflow-x-auto w-full">
          <Table className="min-w-full">
            <TableHeader>
              <TableRow>
                <TableHead className="min-w-[150px]">Device name</TableHead>
                <TableHead className="min-w-[150px]">
                  Device Serial Number
                </TableHead>
                <TableHead className="min-w-[150px]">DID</TableHead>
                <TableHead className="min-w-[150px]">Revision</TableHead>
                <TableHead className="min-w-[100px]">Zone 12VDC</TableHead>
                <TableHead className="min-w-[205px]">
                  SENTEK [30cm] T + H
                </TableHead>
                <TableHead className="min-w-[205px]">
                  SENTEK [30cm] T + H + S
                </TableHead>
                <TableHead className="min-w-[205px]">
                  SENTEK [60cm] T + H
                </TableHead>
                <TableHead className="min-w-[205px]">
                  SENTEK [60cm] T + H + S
                </TableHead>
                <TableHead className="min-w-[205px]">
                  SENTEK [90cm] T + H
                </TableHead>
                <TableHead className="min-w-[205px]">
                  SENTEK [90cm] T + H + S
                </TableHead>
                <TableHead className="min-w-[140px]">
                  Dekagon 10HS Sensor 1
                </TableHead>
                <TableHead className="min-w-[140px]">
                  Dekagon 10HS Sensor 2
                </TableHead>
                <TableHead className="min-w-[120px]">pH Zemljišta</TableHead>
                <TableHead className="min-w-[200px]">FLOW</TableHead>
                <TableHead className="min-w-[130px]">
                  Irigation By time [min]
                </TableHead>
                <TableHead className="min-w-[130px]">
                  Irigation By Volume [m3]
                </TableHead>
                <TableHead className="min-w-[200px]">Napajanje</TableHead>
                <TableHead className="min-w-[200px]">
                  Air Temp. + Humidity
                </TableHead>
                <TableHead className="min-w-[120px]">Wind Speed</TableHead>
                <TableHead className="min-w-[130px]">Wind Direction</TableHead>
                <TableHead className="min-w-[120px]">Rain fall</TableHead>
                <TableHead className="min-w-[130px]">Sun Radiation</TableHead>
                <TableHead className="min-w-[300px]">Comment</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {userSENSDevices?.map((device) => (
                <DeliverySENSDeviceForm
                  device={device}
                  revisionsSENS={revisionsSENS}
                  key={`SENS-device-table-row-${device.id}`}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default SENSDevicesTable;
