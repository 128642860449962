import { FC } from 'react';

import AddDistributorForm from '@/components/forms/AddDistributorForm.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

const CreateDistributorPage: FC = () => {
  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <Card x-chunk="dashboard-06-chunk-0">
            <CardHeader>
              <CardTitle>Novi Distributer</CardTitle>
            </CardHeader>
            <CardContent>
              <AddDistributorForm />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CreateDistributorPage;
