import { createSlice } from '@reduxjs/toolkit';

import {
  FetchOrganization,
  ParcelForOrganization,
} from '@/redux/organizations/organizations.types.ts';

type OrganizationInitalState = {
  organizations: FetchOrganization[];
  parcelsForOrganization: ParcelForOrganization[];
};

const organizationsInitalState: OrganizationInitalState = {
  organizations: [],
  parcelsForOrganization: [],
};

const organizationsSlice = createSlice({
  name: 'organizationsSlice',
  initialState: organizationsInitalState,
  reducers: {
    addOrganization: (state, action) => {
      state.organizations = [...state.organizations, action.payload];
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload || [];
    },
    setParcelsForOrganization: (state, action) => {
      state.parcelsForOrganization = action.payload || [];
    },
  },
});

export const { addOrganization, setOrganizations, setParcelsForOrganization } =
  organizationsSlice.actions;

export default organizationsSlice.reducer;
