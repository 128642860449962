import { FC } from 'react';

import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import DeviceCountStatistic from '@/components/layout/statistics/DeviceCountStatistic.tsx';
import NotSubscribedTable from '@/components/layout/statistics/NotSubscribedTable.tsx';
import ParcelStatistic from '@/components/layout/statistics/ParcelStatistic.tsx';
import SubscribedUsersStatistic from '@/components/layout/statistics/SubscribedUsersStatistic.tsx';
import UserStatistic from '@/components/layout/statistics/UserStatistic.tsx';

const StatisticsPage: FC = () => {
  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <UserStatistic />
        </div>
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <ParcelStatistic />
        </div>
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <DeviceCountStatistic />
        </div>
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <SubscribedUsersStatistic />
        </div>
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <NotSubscribedTable />
        </div>
      </div>
    </div>
  );
};

export default StatisticsPage;
