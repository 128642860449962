import { FC } from 'react';

import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { FetchUser } from '@/redux/users/users.types.ts';
import { formatPaymentDate } from '@/utils/dateUtil.ts';

type UserTableRowProps = {
  user: FetchUser;
};
const NotSubscribedUserTableRow: FC<UserTableRowProps> = ({ user }) => {
  return (
    <TableRow>
      <TableCell className="max-sm:hidden">{user.full_name}</TableCell>
      <TableCell className="max-sm:hidden">{user.wayOfInforming}</TableCell>
      <TableCell className="text-[#007bff] hover:underline font-bold text-wrap flex-wrap break-words break-all whitespace-normal">
        {user.email}
      </TableCell>
      <TableCell className="max-xl:hidden">{user.phoneNumber}</TableCell>
      <TableCell className="max-2xl:hidden">
        {formatPaymentDate(new Date(user.dateOfPayment))}
      </TableCell>
    </TableRow>
  );
};
export default NotSubscribedUserTableRow;
