import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormDatePicker from '@/components/controls/CustomFormDatePicker.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';

type UserCompanyFormProps = {
  form: UseFormReturn<any>;
};

const UserCompanyForm: FC<UserCompanyFormProps> = ({ form }) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="nameOfTheCompany"
        labelText="Naziv privrednog društva ili poljoprivrednog gazdinstva"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="pib"
        labelText="PIB ili BGP"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="mbr"
        labelText="Maticni broj firme ili JMBG"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormDatePicker
        customForm={form}
        name="dateOfPayment"
        labelText="Datum plaćanja pretplate"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        buttonStyle="max-md:w-full w-full py-3 px-5"
      />
    </>
  );
};

export default UserCompanyForm;
