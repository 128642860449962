import { ListFilter } from 'lucide-react';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import FilterCustomSelect from '@/components/controls/FilterCustomSelect.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import {
  getParcelsCountries,
  getParcelsPlaces,
} from '@/redux/parcels/parcels.actions.ts';
import {
  getCountriesForParcels,
  getPlacesForParcels,
} from '@/redux/parcels/parcels.selectors.ts';
import {
  GetParcelsCountriesResponse,
  GetParcelsPlacesResponse,
} from '@/redux/parcels/parcels.types.ts';

type ParcelsFilterSheetProps = {
  selectedCountry: string;
  selectedPlace: string;
  onFilterChange: (filter: string, value: string) => void;
  resetFilters: () => void;
};

const ParcelsFiltersSheet: FC<ParcelsFilterSheetProps> = ({
  selectedCountry,
  selectedPlace,
  onFilterChange,
  resetFilters,
}) => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const countries = useAppSelector(getCountriesForParcels);
  const places = useAppSelector(getPlacesForParcels);

  const countriesForSelect = countries.map((country) => {
    return { value: country.country, label: country.country };
  });
  const placesForSelect = places.map((place) => {
    return { value: place.place, label: place.place };
  });

  const handleErrorResponse = (
    response: GetParcelsCountriesResponse | GetParcelsPlacesResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchParcelsPlaces = async () => {
      // @ts-ignore
      const response = await dispatch(getParcelsPlaces()).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    const fetchParcelsCountries = async () => {
      // @ts-ignore
      const response = await dispatch(getParcelsCountries()).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchParcelsCountries();
    fetchParcelsPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 gap-1">
          <ListFilter className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Filter
          </span>
        </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle className="text-start">Filteri</SheetTitle>
          <SheetDescription className="text-start">
            Odaberite filtere koje želite da primenite
          </SheetDescription>
        </SheetHeader>
        <div className="grid gap-4 py-4 mt-3">
          <FilterCustomSelect
            labelText="Država"
            changeField="realSelectedCountry"
            selectedValue={selectedCountry}
            onFilterChange={onFilterChange}
            filterValues={countriesForSelect}
            placeholder="Odaberite državu"
          />
          <FilterCustomSelect
            labelText="Mesto"
            changeField="realSelectedPlace"
            selectedValue={selectedPlace}
            onFilterChange={onFilterChange}
            filterValues={placesForSelect}
            placeholder="Odaberite mesto"
          />
        </div>
        <SheetFooter>
          <SheetClose asChild>
            <Button
              type="button"
              className="mt-5 touch-pinch-zoom"
              onClick={resetFilters}
            >
              Resetuj filtere
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default ParcelsFiltersSheet;
