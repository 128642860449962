import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import {
  setOrganizations,
  setParcelsForOrganization,
} from '@/redux/organizations/organizations.slice.ts';
import {
  CreateOrganizationResponse,
  GetOrganizationsResponse,
  GetParcelsForOrganizationResponse,
} from '@/redux/organizations/organizations.types.ts';
import axiosServer from '@/services/axios.service.ts';

const createOrganization = createAsyncThunk<CreateOrganizationResponse, string>(
  'admin/create-organization',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.post<CreateOrganizationResponse>(
        '/admin/organization/create-organization',
        { name: reqData }
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getOrganizations = createAsyncThunk<GetOrganizationsResponse, void>(
  'admin/get-organizations',
  async (_reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetOrganizationsResponse>(
        `/admin/organization/get-all`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setOrganizations(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getParcelsForOrganization = createAsyncThunk<
  GetParcelsForOrganizationResponse,
  string
>('admin/get-parcels-for-organization', async (reqData, { dispatch }) => {
  try {
    const axiosResponse =
      await axiosServer.get<GetParcelsForOrganizationResponse>(
        `/admin/organization/get-parcels-for-organization?organizationId=${reqData}`
      );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setParcelsForOrganization(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export { createOrganization, getOrganizations, getParcelsForOrganization };
