import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import UserAddressForm from '@/components/forms/user/UserAddressForm.tsx';
import UserCompanyForm from '@/components/forms/user/UserCompanyForm.tsx';
import UserInfoForm from '@/components/forms/user/UserInfoForm.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { updateUser } from '@/redux/users/users.actions.ts';
import {
  CreateUserResponse,
  UpdateUserResponse,
  User,
} from '@/redux/users/users.types.ts';
import { editUserSchema } from '@/zod/user.types.zod.ts';

export type EditUserFormData = {
  id: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  address: string;
  place: string;
  country: string;
  nameOfTheCompany: string;
  pib: string;
  mbr: string;
  dateOfPayment: Date;
  differentBillingAddress: boolean;
  billingAddress: string;
  billingPlace: string;
  billingCountry: string;
  wayOfInforming: string;
  organizationId: string;
  zip: string;
  language: string;
};

type EditUserFormButtonSectionProps = {
  onGiveUpClick: () => void;
};

type EditUserFormProps = {
  existingUser: User;
};

const EditUserFormButtonSection: FC<EditUserFormButtonSectionProps> = ({
  onGiveUpClick,
}) => {
  return (
    <div className="flex flex-row max-md:flex-col w-full py-10">
      <div className="max-md:w-full flex flex-row flex-wrap gap-5">
        <Button
          className="bg-gray-600 text-white py-3 px-2"
          type="button"
          onClick={onGiveUpClick}
        >
          Odustani
        </Button>
        <Button className="bg-[#007bff] py-3 px-2 text-white" type="submit">
          Izmeni korisnika
        </Button>
        <Button
          className="bg-[#28a745] py-3 px-2 text-white"
          type="button"
          onClick={onGiveUpClick}
        >
          Pošalji račun
        </Button>
      </div>
    </div>
  );
};

const EditUserForm: FC<EditUserFormProps> = ({ existingUser }) => {
  const editForm = useForm({
    resolver: zodResolver(editUserSchema),
    defaultValues: {
      ...existingUser,
      dateOfPayment: new Date(),
      billingAddress: '',
      billingPlace: '',
      billingCountry: '',
    },
  });
  const [activeAccordion, setActiveAccordion] = useState<string>('infoForm');
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const breadcrumbs = useAppSelector(getBreadcrumbs);

  const handleErrorResponse = (
    response: CreateUserResponse | UpdateUserResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const onSubmit = async (data: EditUserFormData) => {
    // @ts-ignore
    const response = await dispatch(updateUser(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Korisnik je uspešno izmenjen');
    onGiveUpClick();
  };

  useEffect(() => {
    editForm.setValue('id', existingUser.id);
    editForm.setValue('name', existingUser.name);
    editForm.setValue('surname', existingUser.surname);
    editForm.setValue('email', existingUser.email);
    editForm.setValue('phoneNumber', existingUser.phoneNumber);
    editForm.setValue('place', existingUser.place);
    editForm.setValue('address', existingUser.address);
    editForm.setValue('zip', existingUser.zip);
    editForm.setValue('language', existingUser.language);
    editForm.setValue('country', existingUser.country);
    editForm.setValue('nameOfTheCompany', existingUser.nameOfTheCompany);
    editForm.setValue('pib', existingUser.pib);
    editForm.setValue('mbr', existingUser.mbr);
    editForm.setValue(
      'dateOfPayment',
      existingUser.dateOfPayment
        ? new Date(existingUser.dateOfPayment)
        : new Date()
    );
    editForm.setValue(
      'differentBillingAddress',
      existingUser.differentBillingAddress
    );
    editForm.setValue('wayOfInforming', existingUser.wayOfInforming);
    editForm.setValue('organizationId', existingUser.organizationId);
    if (existingUser.differentBillingAddress) {
      editForm.setValue('billingAddress', existingUser.billingAddress);
      editForm.setValue('billingPlace', existingUser.billingPlace);
      editForm.setValue('billingCountry', existingUser.billingCountry);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingUser]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editForm.watch('differentBillingAddress' as never)]);

  const handleErrorAccordion = () => {
    const { errors } = editForm.formState;

    if (Object.keys(errors).length === 0) {
      return;
    }

    if (
      errors.name ||
      errors.surname ||
      errors.email ||
      errors.password ||
      errors.phoneNumber ||
      errors.organizationId ||
      errors.wayOfInforming ||
      errors.language
    ) {
      setActiveAccordion('infoForm');

      return;
    }

    setActiveAccordion('addressForm');
  };

  const handleFormSubmit = async () => {
    const isValid = await editForm.trigger();
    if (!isValid) {
      handleErrorAccordion();
      return;
    }

    await editForm.handleSubmit(onSubmit)();
  };

  return (
    <Form
      reset={editForm.reset}
      formState={editForm.formState}
      clearErrors={editForm.clearErrors}
      control={editForm.control}
      getFieldState={editForm.getFieldState}
      getValues={editForm.getValues}
      handleSubmit={editForm.handleSubmit}
      register={editForm.register}
      resetField={editForm.resetField}
      setError={editForm.setError}
      setFocus={editForm.setFocus}
      setValue={editForm.setValue}
      trigger={editForm.trigger}
      unregister={editForm.unregister}
      watch={editForm.watch}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <Accordion
          value={activeAccordion}
          onValueChange={(value) => setActiveAccordion(value || '')}
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovne informacije</AccordionTrigger>
            <AccordionContent>
              <UserInfoForm form={editForm} isEdit />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="addressForm">
            <AccordionTrigger>Podaci o adresi</AccordionTrigger>
            <AccordionContent>
              <UserAddressForm form={editForm} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="companyForm">
            <AccordionTrigger>Podaci o kompaniji</AccordionTrigger>
            <AccordionContent>
              <UserCompanyForm form={editForm} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <EditUserFormButtonSection onGiveUpClick={onGiveUpClick} />
      </form>
    </Form>
  );
};

export default EditUserForm;
