import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CreateParcelBoundaryForm from '@/components/forms/parcels/CreateParcelBoundaryForm.tsx';
import CreateParcelInfoForm from '@/components/forms/parcels/CreateParcelInfoForm.tsx';
import CreateParcelSensorForm from '@/components/forms/parcels/CreateParcelSensorForm.tsx';
import CreateButtonSection from '@/components/forms/shared/CreateButtonSection.tsx';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.selectors.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { createParcel } from '@/redux/parcels/parcels.actions.ts';
import { CreateParcelResponse } from '@/redux/parcels/parcels.types.ts';
import { castStringToNumber } from '@/utils/parcels.ts';
import { createParcelFormData } from '@/zod/parcel.types.zod.ts';

export type AddParcelFormData = {
  name: string;
  numberOfZones: number;
  place: string;
  country: string;
  weatherForecast: string;
  simCardNumber: string;
  boundaryForDryLand: number;
  boundaryForWetlands: number;
  humiditySensor: boolean;
  secondHumiditySensor: boolean;
  supplementMixer: boolean;
  supplement: boolean;
  voltage: boolean;
  rawSensorValues: boolean;
  organizationId: string;
};

const AddParcelForm: FC = () => {
  const form = useForm({
    resolver: zodResolver(createParcelFormData),
    defaultValues: {
      name: '',
      numberOfZones: 1,
      place: '',
      country: '',
      weatherForecast: 'Novi Sad',
      simCardNumber: '',
      boundaryForDryLand: 0,
      boundaryForWetlands: 0,
      humiditySensor: false,
      secondHumiditySensor: false,
      supplementMixer: false,
      supplement: false,
      voltage: false,
      rawSensorValues: true,
      organizationId: '',
    },
  });
  const [activeAccordion, setActiveAccordion] = useState<string>('infoForm');
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const breadcrumbs = useAppSelector(getBreadcrumbs);

  const onGiveUpClick = () => {
    const { link } = breadcrumbs[breadcrumbs.length - 2];
    router(link);
  };

  const handleErrorResponse = (response: CreateParcelResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onSubmit = async (data: AddParcelFormData) => {
    // @ts-ignore
    const response = await dispatch(createParcel(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno kreirana parcela');
    onGiveUpClick();
  };

  const handleErrorAccordion = () => {
    const { errors } = form.formState;

    if (Object.keys(errors).length === 0) {
      return;
    }

    if (
      errors.name ||
      errors.organizationId ||
      errors.numberOfZones ||
      errors.place ||
      errors.country ||
      errors.weatherForecast ||
      errors.simCardNumber
    ) {
      setActiveAccordion('infoForm');

      return;
    }

    setActiveAccordion('boundariesForm');
  };

  const handleFormSubmit = async () => {
    castStringToNumber(form);

    const isValid = await form.trigger();
    if (!isValid) {
      handleErrorAccordion();
      return;
    }

    form.handleSubmit(onSubmit)();
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <Accordion
          value={activeAccordion}
          onValueChange={(value) => setActiveAccordion(value || '')}
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem value="infoForm">
            <AccordionTrigger>Osnovne informacije o parceli</AccordionTrigger>
            <AccordionContent>
              <CreateParcelInfoForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="sensorsForm">
            <AccordionTrigger>Senzori</AccordionTrigger>
            <AccordionContent>
              <CreateParcelSensorForm form={form} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="boundariesForm">
            <AccordionTrigger>Granice za zemljište</AccordionTrigger>
            <AccordionContent>
              <CreateParcelBoundaryForm form={form} />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        <CreateButtonSection
          onGiveUpClick={onGiveUpClick}
          submitText="Dodaj parcelu"
        />
      </form>
    </Form>
  );
};

export default AddParcelForm;
