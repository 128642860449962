import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { Form } from '@/components/ui/form.tsx';
import { TableCell } from '@/components/ui/table.tsx';

type TableCellInputFormProps = {
  form: UseFormReturn<any>;
  field: string;
  inputStyle?: string;
  cellStyle?: string;
  isDisabled?: boolean;
};

const TableCellInputForm: FC<TableCellInputFormProps> = ({
  form,
  field,
  inputStyle,
  cellStyle,
  isDisabled,
}) => {
  return (
    <TableCell className={cellStyle}>
      <Form
        reset={form.reset}
        formState={form.formState}
        clearErrors={form.clearErrors}
        control={form.control}
        getFieldState={form.getFieldState}
        getValues={form.getValues}
        handleSubmit={form.handleSubmit}
        register={form.register}
        resetField={form.resetField}
        setError={form.setError}
        setFocus={form.setFocus}
        setValue={form.setValue}
        trigger={form.trigger}
        unregister={form.unregister}
        watch={form.watch}
      >
        <form>
          <CustomFormInput
            customForm={form}
            name={field}
            labelText=""
            type="text"
            inputStyle={inputStyle}
            isDisabled={isDisabled}
          />
        </form>
      </Form>
    </TableCell>
  );
};

export default TableCellInputForm;
