import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CreateFERTDeviceForm from '@/components/forms/CreateFERTDeviceForm.tsx';
import CreateSensDeviceForm from '@/components/forms/CreateSensDeviceForm.tsx';
import CreateStartDeviceForm from '@/components/forms/CreateStartDeviceForm.tsx';
import SelectTypeButton from '@/components/layout/devices/SelectTypeButton.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getAvailableZonesForParcel } from '@/redux/device/devices.actions.ts';
import {
  deviceTypes,
  GetAvailableZonesResponse,
} from '@/redux/device/devices.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

const CreateDeviceContent: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>('');

  const onStartClick = () => {
    setSelectedDeviceType(deviceTypes.START);
  };

  const onSENSClick = () => {
    setSelectedDeviceType(deviceTypes.SENS);
  };

  const onFERTClick = () => {
    setSelectedDeviceType(deviceTypes.FERT);
  };

  const handleErrorResponse = (response: GetAvailableZonesResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchAvailableZones = async () => {
      // @ts-ignore
      const response = await dispatch(
        getAvailableZonesForParcel(params.parcelId)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchAvailableZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card x-chunk="dashboard-06-chunk-0">
      <CardHeader>
        <CardTitle className="flex items-center justify-between w-full py-3">
          <p className="max-sm:text-start text-center">Novi uredjaj</p>
          <SelectTypeButton
            onFERTClick={onFERTClick}
            onStartClick={onStartClick}
            onSENSClick={onSENSClick}
            selectedDeviceType={selectedDeviceType}
          />
        </CardTitle>
        <CardContent className="p-0">
          {selectedDeviceType === deviceTypes.FERT && <CreateFERTDeviceForm />}
          {selectedDeviceType === deviceTypes.START && (
            <CreateStartDeviceForm />
          )}
          {selectedDeviceType === deviceTypes.SENS && <CreateSensDeviceForm />}
        </CardContent>
      </CardHeader>
    </Card>
  );
};

export default CreateDeviceContent;
