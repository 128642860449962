import { FC, useEffect, useState } from 'react';

import { Checkbox } from '@/components/ui/checkbox.tsx';

type DidsFilterSheetProps = {
  onFilterChange: (filter: string, value: string) => void;
  selectedOccupation: string;
};

const DidsFilterSheet: FC<DidsFilterSheetProps> = ({
  selectedOccupation,
  onFilterChange,
}) => {
  const [isOccupiedSelected, setIsOccupiedSelected] = useState(false);
  const [isFreeSelected, setIsFreeSelected] = useState(false);

  const onFilterChangeBasedOnState = (occupied: boolean, free: boolean) => {
    if (occupied && free) {
      onFilterChange('realSelectedOccupation', '');

      return;
    }

    if (occupied) {
      onFilterChange('realSelectedOccupation', 'true');

      return;
    }

    if (free) {
      onFilterChange('realSelectedOccupation', 'false');

      return;
    }

    onFilterChange('realSelectedOccupation', '');
  };

  const handleCheckboxChange = (filter: 'occupied' | 'free') => {
    if (filter === 'occupied') {
      const newState = !isOccupiedSelected;
      setIsOccupiedSelected(newState);
      onFilterChangeBasedOnState(newState, isFreeSelected);

      return;
    }

    const newState = !isFreeSelected;
    setIsFreeSelected(newState);
    onFilterChangeBasedOnState(isOccupiedSelected, newState);
  };

  useEffect(() => {
    if (selectedOccupation === 'true') {
      setIsOccupiedSelected(true);
      setIsFreeSelected(false);

      return;
    }
    if (selectedOccupation === 'false') {
      setIsOccupiedSelected(false);
      setIsFreeSelected(true);

      return;
    }

    setIsOccupiedSelected(true);
    setIsFreeSelected(true);
  }, [selectedOccupation]);

  return (
    <div className="grid gap-4 py-4 mt-3">
      <div className="flex items-center space-x-2">
        <span className="mr-1">Odaberite zauzetost:</span>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="occupiedCheckbox"
            checked={isOccupiedSelected}
            onClick={() => handleCheckboxChange('occupied')}
          />
          <div className="grid gap-1.5 leading-none">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor="occupiedCheckbox"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Zauzet
            </label>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <Checkbox
            id="freeCheckbox"
            checked={isFreeSelected}
            onClick={() => handleCheckboxChange('free')}
          />
          <div className="grid gap-1.5 leading-none">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
              htmlFor="freeCheckbox"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Slobodan
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DidsFilterSheet;
