import { createSlice } from '@reduxjs/toolkit';

import { DID, UpdateDid } from '@/redux/did/did.types.ts';

type DidInitialState = {
  dids: DID[];
  updatedDid: UpdateDid;
  didByDeviceId: DID;
  occupiedDidsWithoutDevice: DID[];
};

const didInitialState: DidInitialState = {
  dids: [],
  updatedDid: {
    did: '',
    device: '',
    dsn: '',
    occupied: true,
    revision: '',
    deviceId: '',
  },
  occupiedDidsWithoutDevice: [],
  didByDeviceId: {
    id: '',
    did: '',
    device: '',
    revision: '',
    dsn: '',
    occupied: false,
    deviceId: '',
  },
};

const didsSlice = createSlice({
  name: 'didsSlice',
  initialState: didInitialState,
  reducers: {
    setDids: (state, action) => {
      state.dids = [...state.dids, ...action.payload];
    },
    updatedDid: (state, action) => {
      state.updatedDid = action.payload || {};
    },
    occupiedDidsWithoutDevice: (state, action) => {
      state.occupiedDidsWithoutDevice = action.payload || [];
    },
    didByDeviceId: (state, action) => {
      state.didByDeviceId = action.payload || {};
    },
  },
});

export const { setDids, updatedDid, occupiedDidsWithoutDevice, didByDeviceId } =
  didsSlice.actions;

export default didsSlice.reducer;
