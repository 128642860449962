import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import BillingAddressForm from '@/components/forms/user/BillingAddressForm.tsx';
import { countries } from '@/utils/countryUtil.ts';

type UserAddressFormProps = {
  form: UseFormReturn<any>;
};

const UserAddressForm: FC<UserAddressFormProps> = ({ form }) => {
  const countriesComboboxContent: ComboboxItemType[] = countries.map(
    (country) => {
      return {
        value: country,
        label: country,
      };
    }
  );

  return (
    <>
      <CustomFormInput
        customForm={form}
        name="address"
        labelText="Adresa"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="place"
        labelText="Mesto"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="zip"
        labelText="Poštanski broj"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormCombobox
        customForm={form}
        name="country"
        labelText="Država"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full w-[300px]"
        buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
        items={countriesComboboxContent}
        placeholder="Odaberite državu"
      />
      <BillingAddressForm
        form={form}
        isFormShown={form.getValues('differentBillingAddress' as never)}
      />
    </>
  );
};

export default UserAddressForm;
