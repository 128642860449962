import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { MultiSelect } from '@/components/ui/multi-select.tsx';

export type MultiSelectItem = {
  label: string;
  value: string;
  icon?: any;
};

type CustomFormMultiSelectProps = {
  customForm: UseFormReturn<any, any, any>;
  name: string;
  labelText: string;
  formItemStyle?: string;
  labelStyle?: string;
  placeholder?: string;
  items: MultiSelectItem[];
};

const CustomFormMultiSelect: FC<CustomFormMultiSelectProps> = ({
  customForm,
  name,
  labelText,
  labelStyle,
  formItemStyle,
  placeholder,
  items,
}) => {
  return (
    <FormField
      control={customForm.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className={labelStyle}>{labelText}</FormLabel>
          <FormControl>
            <MultiSelect
              options={items}
              value={field.value}
              onValueChange={field.onChange}
              placeholder={placeholder}
              variant="inverted"
              animation={2}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CustomFormMultiSelect;
