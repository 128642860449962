import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  NumberOfUsersByCountry,
  UserLastUsage,
  UserNumberOfUtilization,
  UserUtils,
} from '@/redux/statistics/statistics.types.ts';
import { FetchUser } from '@/redux/users/users.types.ts';

type StatisticsSliceInitialState = {
  numberOfUsersByCountry: NumberOfUsersByCountry[];
  lastUsage: UserLastUsage[];
  numberOfUtilization: UserNumberOfUtilization[];
  userUtilization: UserUtils[];
  numberOfActiveParcels: number;
  numberOfParcels: number;
  FERTActive: number;
  STARTActive: number;
  SENSActive: number;
  FERTNotActive: number;
  STARTNotActive: number;
  SENSNotActive: number;
  subscribed: number;
  notSubscribed: number;
  users: FetchUser[];
};

const statisticsInitialState: StatisticsSliceInitialState = {
  numberOfUsersByCountry: [],
  lastUsage: [],
  numberOfUtilization: [],
  userUtilization: [],
  numberOfActiveParcels: 0,
  numberOfParcels: 0,
  FERTActive: 0,
  STARTActive: 0,
  SENSActive: 0,
  FERTNotActive: 0,
  SENSNotActive: 0,
  STARTNotActive: 0,
  subscribed: 0,
  notSubscribed: 0,
  users: [],
};

const statisticsSlice = createSlice({
  name: 'statisticsSlice',
  initialState: statisticsInitialState,
  reducers: {
    setNumberOfUsersByCountry: (
      state,
      action: PayloadAction<NumberOfUsersByCountry[]>
    ) => {
      state.numberOfUsersByCountry = action.payload || [];
    },
    setUtilization: (state, action: PayloadAction<UserUtils[]>) => {
      state.userUtilization = action.payload;
      state.lastUsage = [];
      state.numberOfUtilization = [];
      action.payload.forEach((user) => {
        state.lastUsage.push({ lastUsage: user.lastUsage, user: user.user });
        state.numberOfUtilization.push({
          numberOfUtilization: user.numberOfUtilization,
          user: user.user,
        });
      });
    },
    setNumberOfActiveParcels: (state, action: PayloadAction<number>) => {
      state.numberOfActiveParcels = action.payload || 0;
    },
    setNumberOfParcels: (state, action: PayloadAction<number>) => {
      state.numberOfParcels = action.payload || 0;
    },
    setNumberOfActiveFERTs: (state, action: PayloadAction<number>) => {
      state.FERTActive = action.payload || 0;
    },
    setNumberOfActiveSTARTs: (state, action: PayloadAction<number>) => {
      state.STARTActive = action.payload || 0;
    },
    setNumberOfActiveSENSs: (state, action: PayloadAction<number>) => {
      state.SENSActive = action.payload || 0;
    },
    setNumberOfNotActiveFERTs: (state, action: PayloadAction<number>) => {
      state.FERTNotActive = action.payload || 0;
    },
    setNumberOfNotActiveSTARTs: (state, action: PayloadAction<number>) => {
      state.STARTNotActive = action.payload || 0;
    },
    setNumberOfNotActiveSENSs: (state, action: PayloadAction<number>) => {
      state.SENSNotActive = action.payload || 0;
    },
    setNumberOfSubscribedUsers: (state, action: PayloadAction<number>) => {
      state.subscribed = action.payload || 0;
    },
    setNumberOfNotSubscribedUsers: (state, action: PayloadAction<number>) => {
      state.notSubscribed = action.payload || 0;
    },
    setNotSubscribedUsers: (state, action: PayloadAction<FetchUser[]>) => {
      state.users = action.payload || [];
    },
  },
});

export const {
  setNumberOfUsersByCountry,
  setUtilization,
  setNumberOfActiveParcels,
  setNumberOfParcels,
  setNumberOfActiveSTARTs,
  setNumberOfActiveSENSs,
  setNumberOfActiveFERTs,
  setNumberOfNotActiveSENSs,
  setNumberOfNotActiveSTARTs,
  setNumberOfNotActiveFERTs,
  setNumberOfNotSubscribedUsers,
  setNumberOfSubscribedUsers,
  setNotSubscribedUsers,
} = statisticsSlice.actions;
export default statisticsSlice.reducer;
