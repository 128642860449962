import { zodResolver } from '@hookform/resolvers/zod';
import debounce from 'lodash/debounce';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DropDownItems } from '@/components/controls/CustomFormDropDown.tsx';
import TableCellDropdownForm from '@/components/forms/delivery/controls/TableCellDropdownForm.tsx';
import TableCellInputForm from '@/components/forms/delivery/controls/TableCellInputForm.tsx';
import { TableCell, TableRow } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getDevicesRevisions } from '@/redux/device/devices.selectors.ts';
import { DeviceRevision, DeviceType } from '@/redux/device/devices.types.ts';
import { updateDid } from '@/redux/did/did.actions.ts';
import { UpdateDidResponse } from '@/redux/did/did.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { updateDidSchema } from '@/zod/did.types.zod.ts';

type DidAllString = {
  id: string;
  did: string;
  device: string;
  revision: string;
  dsn: string;
  occupied: string;
  deviceId: string;
};

type DidTableRowProps = {
  did: DidAllString;
};

const deviceSelect = ['FERT', 'START', 'SENS'];
const occupiedSelect = [
  { occupied: 'Zauzet', value: 'true' },
  { occupied: 'Slobodan', value: 'false' },
];

const compareByRevision = (a: DeviceRevision, b: DeviceRevision) => {
  return a.revision.localeCompare(b.revision);
};

const DidTableRow: FC<DidTableRowProps> = ({ did }) => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const form = useForm({
    resolver: zodResolver(updateDidSchema),
    defaultValues: {
      id: did.id,
      did: did.did,
      revision: did.revision,
      dsn: did.dsn,
      occupied: did.occupied,
      device: did.device,
    },
  });
  const { handleSubmit, watch } = form;
  const [copied, setCopied] = useState<boolean>(false);
  const [isOccupied, setIsOccupied] = useState(did.occupied === 'true');
  const deviceRevisions = useAppSelector(getDevicesRevisions);
  const revisionsFERT = deviceRevisions
    .filter((rev) => rev.deviceType === DeviceType.FERT)
    .sort(compareByRevision);
  const revisionsSTART = deviceRevisions
    .filter((rev) => rev.deviceType === DeviceType.START)
    .sort(compareByRevision);
  const revisionsSENS = deviceRevisions
    .filter((rev) => rev.deviceType === DeviceType.SENS)
    .sort(compareByRevision);

  const deviceDropdown: DropDownItems[] = deviceSelect.map((device) => {
    return {
      text: device,
      value: device,
    };
  });

  const occupiedDropdown: DropDownItems[] = occupiedSelect.map((occupied) => {
    return {
      text: occupied.occupied,
      value: occupied.value,
    };
  });

  const revisionFERTDropdown: DropDownItems[] = revisionsFERT.map((rev) => {
    return {
      text: rev.revision,
      value: rev.revision,
    };
  });

  const revisionStartDropdown: DropDownItems[] = revisionsSTART.map((rev) => {
    return {
      text: rev.revision,
      value: rev.revision,
    };
  });

  const revisionSENSDropdown: DropDownItems[] = revisionsSENS.map((rev) => {
    return {
      text: rev.revision,
      value: rev.revision,
    };
  });

  const [revisions, setRevisions] = useState(revisionFERTDropdown);

  useEffect(() => {
    setIsOccupied(did.occupied === 'true');
  }, [did.occupied]);

  const selectedDevice = watch('device');

  useEffect(() => {
    if (selectedDevice === 'START') {
      setRevisions(revisionStartDropdown);
    }
    if (selectedDevice === 'FERT') {
      setRevisions(revisionFERTDropdown);
    }
    if (selectedDevice === 'SENS') {
      setRevisions(revisionSENSDropdown);
    }
  }, [
    revisionFERTDropdown,
    revisionSENSDropdown,
    revisionStartDropdown,
    selectedDevice,
  ]);

  const handleErrorResponse = (response: UpdateDidResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = async (updatedDid: DidAllString) => {
    const updatedDidWithBoolean = {
      ...updatedDid,
      occupied: updatedDid.occupied === 'true',
    };
    const updateDidFun = async () => {
      const response = await dispatch(
        updateDid(updatedDidWithBoolean)
      ).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
        return;
      }

      setIsOccupied(response.content.occupied);
    };

    updateDidFun();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce(() => {
      handleSubmit(onSubmit)();
    }, 1000),
    []
  );

  useEffect(() => {
    form.reset({
      id: did.id,
      did: did.did,
      revision: did.revision,
      dsn: did.dsn,
      occupied: did.occupied,
      device: did.device,
    });
  }, [form, did]);

  useEffect(() => {
    const subscription = watch(async (_, { name }) => {
      try {
        if (name === 'dsn' || name === 'revision') {
          debouncedChangeHandler();
          return;
        }

        await handleSubmit(onSubmit)();
      } catch (error) {
        toast.error('Error during submission');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onSubmit, debouncedChangeHandler]);

  const isFieldDisabled =
    did.deviceId !== '00000000-0000-0000-0000-000000000000';

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(did.did).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    });
  };

  return (
    <TableRow className="bg-white">
      <TableCell className="width-70 text-xs-under-700 max-sm:p-2 max-sm:pl-4">
        <div className="flex items-center">
          <span>{did.did}</span>
          <div className="relative inline-block">
            {copied && (
              <div className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 text-[#2b90d9] text-xs px-2 py-1 rounded animate-fade">
                Copied!
              </div>
            )}
            <button
              onClick={handleCopyToClipboard}
              className="text-black ml-4 p-1 px-3 text-xs max-md:hidden rounded-xl hover:bg-gray-500 hover:text-white"
            >
              Copy to Clipboard
            </button>
          </div>
        </div>
      </TableCell>
      <TableCellDropdownForm
        formItemStyle="w-[150px]"
        form={form}
        items={deviceDropdown}
        field="device"
        isDisabled={isFieldDisabled}
        cellStyle="max-sm:hidden max-xl:hidden"
      />
      <TableCellDropdownForm
        formItemStyle="w-[150px]"
        form={form}
        items={revisions}
        field="revision"
        isDisabled={isFieldDisabled}
        cellStyle="max-sm:hidden max-lg:hidden"
      />
      <TableCellInputForm
        form={form}
        field="dsn"
        inputStyle="bg-transparent w-[150px] width-55 text-xs-under-700"
        cellStyle="max-sm:justify-items-center max-sm:px-3"
        isDisabled={isFieldDisabled}
      />
      <TableCellDropdownForm
        formItemStyle="w-[150px] width-110"
        form={form}
        items={occupiedDropdown}
        field="occupied"
        isDisabled={isFieldDisabled}
        triggerStyle={`${isOccupied ? 'bg-red-500' : 'bg-green-500'} hover:${isOccupied ? 'bg-red-500' : 'bg-green-600'} text-xs-under-700 width-95`}
        cellStyle="max-sm:px-3"
      />
    </TableRow>
  );
};
export default DidTableRow;
