import { FC } from 'react';

import { Button } from '@/components/ui/button.tsx';

type CreateButtonSectionProps = {
  onGiveUpClick: () => void;
  submitText: string;
};

const CreateButtonSection: FC<CreateButtonSectionProps> = ({
  onGiveUpClick,
  submitText,
}) => {
  return (
    <div className="flex flex-row max-md:flex-col w-full py-10">
      <div className="max-md:w-full flex flex-row gap-5 flex-wrap">
        <Button
          className="bg-gray-600 text-white py-3 px-2"
          onClick={onGiveUpClick}
          type="button"
        >
          Odustani
        </Button>
        <Button className="bg-[#007bff] py-3 px-2 text-white" type="submit">
          {submitText}
        </Button>
      </div>
    </div>
  );
};

export default CreateButtonSection;
