import '@blocknote/shadcn/style.css';
import '@blocknote/core/fonts/inter.css';
import { FC } from 'react';

import MassMailForm from '@/components/forms/user/MassMailForm.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

const MassMailPage: FC = () => {
  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <Card x-chunk="dashboard-users">
            <CardHeader>
              <CardTitle>Email poruka</CardTitle>
            </CardHeader>
            <CardContent>
              <MassMailForm />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MassMailPage;
