import { z } from 'zod';

export const massMailSchema = z.object({
  subject: z.string().min(1, {
    message: 'Niste uneli naslov mejla',
  }),
  language: z.string().min(1, {
    message: 'Niste odabrali jezik korisnika',
  }),
  emailMessage: z.string().min(1, {
    message: 'Poruka za mejl nije uneta',
  }),
});
