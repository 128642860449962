import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import FruitTypesContent from '@/components/layout/fruits/FruitTypesContent.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { GetFruitSortsResponse } from '@/redux/fruitSort/fruitSort.types.ts';
import { getAllFruitTypes } from '@/redux/fruitType/fruitType.actions.ts';
import { GetFruitTypesResponse } from '@/redux/fruitType/fruitType.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

const FruitsPage: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (
    response: GetFruitTypesResponse | GetFruitSortsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchFruitTypes = async () => {
      // @ts-ignore
      const response = await dispatch(getAllFruitTypes()).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchFruitTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div>
          <FruitTypesContent />
        </div>
      </div>
    </div>
  );
};

export default FruitsPage;
