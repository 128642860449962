import debounce from 'lodash/debounce';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddNewIcon from '@/components/controls/AddNewIcon.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import UsersFilterSheet from '@/components/layout/users/UsersFilterSheet.tsx';
import UsersTableSection from '@/components/layout/users/UsersTableSection.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { deleteUser, getFilteredUsers } from '@/redux/users/users.actions.ts';
import {
  CreateUserResponse,
  GetParcelsForUserResponse,
  GetUsersResponse,
} from '@/redux/users/users.types.ts';

type FilterObject = {
  realPage: number;
  realRowsPerPage: number;
  realSortColumn: string;
  realSortAsc: boolean;
  realInputText: string;
  realSelectedCountry: string;
  realSelectedPlace: string;
  realSelectedYearOfCreation: string;
  realSelectedPaidSubscription: string;
};

const UsersPage: FC = () => {
  const router = useNavigate();

  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('createdAt');
  const [isSortAsc, setIsSortAsc] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedPlace, setSelectedPlace] = useState<string>('');
  const [selectedYearOfCreation, setSelectedYearOfCreation] =
    useState<string>('');
  const [selectedPaidSubscription, setSelectedPaidSubscription] =
    useState<string>('');

  const createQueryParams = (filterObject: FilterObject) => {
    const {
      realPage,
      realSortColumn,
      realRowsPerPage,
      realSortAsc,
      realInputText,
      realSelectedCountry,
      realSelectedPlace,
      realSelectedPaidSubscription,
      realSelectedYearOfCreation,
    } = filterObject;
    return `?page=${realPage}&perPage=${realRowsPerPage}${realSortColumn ? `&sortColumn=${realSortColumn}&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realInputText ? `&inputText=${realInputText}` : ''}${realSelectedCountry ? `&country=${realSelectedCountry}` : ''}${realSelectedPlace ? `&place=${realSelectedPlace}` : ''}${realSelectedPaidSubscription ? `&paidSubscription=${realSelectedPaidSubscription}` : ''}${realSelectedYearOfCreation ? `&yearOfCreation=${realSelectedYearOfCreation}` : ''}`;
  };

  const callFetchUsers = async (queryParams: string) => {
    // @ts-ignore
    return dispatch(getFilteredUsers(queryParams)).unwrap();
  };

  const handleErrorResponse = (
    response: GetUsersResponse | GetParcelsForUserResponse | CreateUserResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDebounceCall = async (value: string) => {
    setInputText(value);
    setPage(0);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: value,
      realSelectedCountry: selectedCountry,
      realSelectedPlace: selectedPlace,
      realSelectedYearOfCreation: selectedYearOfCreation,
      realSelectedPaidSubscription: selectedPaidSubscription,
    };
    const queryParams = createQueryParams(filterObject);
    await callFetchUsers(queryParams);
  };

  const debouncedChangeHandler = debounce((value: string) => {
    onDebounceCall(value);
  }, 500);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    debouncedChangeHandler(value);
  };

  const onColumnClick = async (columnName: string) => {
    if (columnName === sortColumn) {
      setIsSortAsc(!isSortAsc);
      const filterObject: FilterObject = {
        realPage: page,
        realRowsPerPage: rowsPerPage,
        realSortColumn: sortColumn,
        realSortAsc: !isSortAsc,
        realInputText: inputText,
        realSelectedCountry: selectedCountry,
        realSelectedPlace: selectedPlace,
        realSelectedYearOfCreation: selectedYearOfCreation,
        realSelectedPaidSubscription: selectedPaidSubscription,
      };
      const queryParams = createQueryParams(filterObject);
      await callFetchUsers(queryParams);
      return;
    }

    setSortColumn(columnName);
    setIsSortAsc(true);
    const filterObject: FilterObject = {
      realPage: page,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: true,
      realInputText: inputText,
      realSelectedCountry: selectedCountry,
      realSelectedPlace: selectedPlace,
      realSelectedYearOfCreation: selectedYearOfCreation,
      realSelectedPaidSubscription: selectedPaidSubscription,
    };
    const queryParams = createQueryParams(filterObject);
    await callFetchUsers(queryParams);
  };

  const onPageChangeClick = async (selectedPage: number) => {
    const filterObject: FilterObject = {
      realPage: selectedPage,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSelectedCountry: selectedCountry,
      realSelectedPlace: selectedPlace,
      realSelectedYearOfCreation: selectedYearOfCreation,
      realSelectedPaidSubscription: selectedPaidSubscription,
    };
    const queryParams = createQueryParams(filterObject);
    setPage(selectedPage);
    await callFetchUsers(queryParams);
  };

  const fetchUsers = async () => {
    const filterObject: FilterObject = {
      realPage: page,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSelectedCountry: selectedCountry,
      realSelectedPlace: selectedPlace,
      realSelectedYearOfCreation: selectedYearOfCreation,
      realSelectedPaidSubscription: selectedPaidSubscription,
    };
    const queryParams = createQueryParams(filterObject);
    const response = await callFetchUsers(queryParams);
    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  const onDeleteUserClick = async (userId: string, organizationId: string) => {
    // @ts-ignore
    const response = await dispatch(
      deleteUser({ userId, organizationId })
    ).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    await fetchUsers();
    toast.success('Korisnik je uspešno izbrisan');
  };

  const filterUpdateToChange: Record<
    string,
    (value: ((prevState: string) => string) | string) => void
  > = {
    realSelectedCountry: setSelectedCountry,
    realSelectedPlace: setSelectedPlace,
    realSelectedPaidSubscription: setSelectedPaidSubscription,
    realSelectedYearOfCreation: setSelectedYearOfCreation,
  };

  const onFilterChange = async (filter: string, value: string) => {
    filterUpdateToChange[filter](value);
    setPage(0);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSelectedCountry: selectedCountry,
      realSelectedPlace: selectedPlace,
      realSelectedYearOfCreation: selectedYearOfCreation,
      realSelectedPaidSubscription: selectedPaidSubscription,
    };

    filterObject[filter] = value;
    const queryParams = createQueryParams(filterObject);
    await callFetchUsers(queryParams);
  };

  const onResetFilters = async () => {
    setSelectedCountry('');
    setSelectedYearOfCreation('');
    setSelectedPlace('');
    setSelectedPaidSubscription('');
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSelectedCountry: '',
      realSelectedPlace: '',
      realSelectedYearOfCreation: '',
      realSelectedPaidSubscription: '',
    };

    const queryParams = createQueryParams(filterObject);
    await callFetchUsers(queryParams);
  };

  const onRowsPerPageChange = async (value: number) => {
    setPage(0);
    setRowsPerPage(value);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: value,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
      realSelectedCountry: selectedCountry,
      realSelectedPlace: selectedPlace,
      realSelectedYearOfCreation: selectedYearOfCreation,
      realSelectedPaidSubscription: selectedPaidSubscription,
    };

    const queryParams = createQueryParams(filterObject);
    await callFetchUsers(queryParams);
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput handleChange={handleChange} />
        <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 sm:mt-5">
          <div className="flex items-center gap-2 justify-start">
            <UsersFilterSheet
              selectedCountry={selectedCountry}
              selectedPlace={selectedPlace}
              selectedPaidSubscription={selectedPaidSubscription}
              selectedYearOfCreation={selectedYearOfCreation}
              onFilterChange={onFilterChange}
              resetFilters={onResetFilters}
            />
            <AddNewIcon link="/users/create" text="Dodajte korisnika" />
          </div>
          <UsersTableSection
            page={page}
            rowsPerPage={rowsPerPage}
            onColumnClick={onColumnClick}
            onPageChangeClick={onPageChangeClick}
            onDeleteUser={onDeleteUserClick}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
