import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormDropDown from '@/components/controls/CustomFormDropDown.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CustomFormMultiSelect from '@/components/controls/CustomFormMultiSelect.tsx';
import { batteryTypesSENS } from '@/utils/device.util.ts';

type SensDeviceInfoFormProps = {
  form: UseFormReturn<any>;
  multiSelectData: { label: string; value: string }[];
};

const SensDeviceInfoForm: FC<SensDeviceInfoFormProps> = ({
  form,
  multiSelectData,
}) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="name"
        labelText="Ime uredjaja"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="text"
      />
      <CustomFormInput
        customForm={form}
        name="color"
        labelText="Boja uredjaja"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-md:w-full"
        divStyle="w-[50px]"
        type="color"
      />
      <CustomFormInput
        customForm={form}
        name="standbyModeTime"
        labelText="Vreme u standBy modu"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        min="0"
      />
      <CustomFormDropDown
        customForm={form}
        name="batteryType"
        labelText="Napajanje"
        formItemStyle="flex w-full flex-col px-2 max-w-[520px] mt-2 sm:mt-5"
        items={batteryTypesSENS}
        placeholder="Odaberite vrstu napajanja"
      />
      <CustomFormMultiSelect
        customForm={form}
        name="zonesForDevice"
        labelText="Zone za uredjaj"
        items={multiSelectData}
        formItemStyle="flex w-full flex-col px-2 max-w-[520px] mt-10"
        placeholder="Izaberite zonu/e"
      />
    </>
  );
};

export default SensDeviceInfoForm;
