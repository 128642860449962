import { FC } from 'react';

import DevicesAvailabilityStatistics from '@/components/layout/parcels/DevicesAvailabilityStatistics.tsx';
import IrrigationInfoStatistics from '@/components/layout/parcels/IrrigationInfoStatistics.tsx';
import SensDevicesHumidityStatistics from '@/components/layout/parcels/SensDevicesHumidityStatistics.tsx';
import { Accordion } from '@/components/ui/accordion.tsx';
import { ParcelWithDevices } from '@/redux/parcels/parcels.types.ts';

type ParcelsStatisticCardProps = {
  parcel: ParcelWithDevices;
};

const ParcelsStatisticCard: FC<ParcelsStatisticCardProps> = ({ parcel }) => {
  return (
    <Accordion
      defaultValue={[
        'irrigationInfo',
        'humiditySensDevices',
        'devicesAvailability',
      ]}
      type="multiple"
      className="w-full"
    >
      <IrrigationInfoStatistics />
      <SensDevicesHumidityStatistics sensDevices={parcel.sensDevices} />
      <DevicesAvailabilityStatistics
        fertDevices={parcel.fertDevices}
        sensDevices={parcel.sensDevices}
        startDevices={parcel.startDevices}
      />
    </Accordion>
  );
};

export default ParcelsStatisticCard;
