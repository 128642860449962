import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Distributor } from '@/redux/distributors/distributors.types.ts';
import { FetchUser } from '@/redux/users/users.types.ts';

type DistributorsInitialState = {
  distributors: Distributor[];
  totalNumberOfDistributors: number;
  filteredNumberOfDistributors: number;
  usersForSelectedDistributor: FetchUser[];
  totalNumberOfUsersForDistributor: number;
  filteredNumberOfUsersForDistributor: number;
  distributor: Distributor;
};

const distributorsInitialState: DistributorsInitialState = {
  distributors: [],
  totalNumberOfDistributors: 0,
  filteredNumberOfDistributors: 0,
  usersForSelectedDistributor: [],
  totalNumberOfUsersForDistributor: 0,
  filteredNumberOfUsersForDistributor: 0,
  distributor: {
    id: '',
    name: '',
    email: '',
    phoneNumber: '',
    address: '',
    place: '',
    country: '',
    number_of_users: 0,
    createdAt: '',
    createdBy: '',
  },
};

const distributorsSlice = createSlice({
  name: 'distributorsSlice',
  initialState: distributorsInitialState,
  reducers: {
    addDistributor: (state, action: PayloadAction<Distributor>) => {
      state.distributors = [...state.distributors, action.payload];
    },
    setDistributors: (state, action: PayloadAction<Distributor[]>) => {
      state.distributors = action.payload || [];
    },
    setTotalNumberOfDistributors: (state, action: PayloadAction<number>) => {
      state.totalNumberOfDistributors = action.payload;
    },
    setFilteredNumberOfDistributors: (state, action: PayloadAction<number>) => {
      state.filteredNumberOfDistributors = action.payload;
    },
    setUpdatedDistributor: (state, action: PayloadAction<Distributor>) => {
      const foundIndex = state.distributors.findIndex(
        (distributor) => distributor.id === action.payload.id
      );

      if (foundIndex !== -1) {
        state.distributors[foundIndex] = action.payload;
      }
    },
    setUsersForSelectedDistributor: (
      state,
      action: PayloadAction<FetchUser[]>
    ) => {
      state.usersForSelectedDistributor = action.payload;
    },
    setTotalNumberOfUsersForDistributors: (
      state,
      action: PayloadAction<number>
    ) => {
      state.totalNumberOfUsersForDistributor = action.payload;
    },
    setFilteredNumberOfUsersForDistributors: (
      state,
      action: PayloadAction<number>
    ) => {
      state.filteredNumberOfUsersForDistributor = action.payload;
    },
    updateTotalNumberOfUsersForDistributor: (
      state,
      action: PayloadAction<number>
    ) => {
      state.totalNumberOfUsersForDistributor += action.payload;
    },
    setDistributor: (state, action: PayloadAction<Distributor>) => {
      state.distributor = action.payload;
    },
  },
});

export const {
  addDistributor,
  setDistributors,
  setTotalNumberOfDistributors,
  setFilteredNumberOfDistributors,
  setUpdatedDistributor,
  setUsersForSelectedDistributor,
  setTotalNumberOfUsersForDistributors,
  setFilteredNumberOfUsersForDistributors,
  updateTotalNumberOfUsersForDistributor,
  setDistributor,
} = distributorsSlice.actions;
export default distributorsSlice.reducer;
