import { Circle } from 'lucide-react';
import { FC } from 'react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { compareByNameLocale } from '@/lib/utils.ts';
import {
  DeviceAvailability,
  FERTDevice,
  SensDevice,
  StartDevice,
} from '@/redux/device/devices.types.ts';

type DeviceAvailabilityCardProps = {
  deviceName: string;
  status: DeviceAvailability;
};

type DevicesAvailabilityStatisticsProps = {
  fertDevices: FERTDevice[];
  sensDevices: SensDevice[];
  startDevices: StartDevice[];
};

const DeviceAvailabilityCard: FC<DeviceAvailabilityCardProps> = ({
  deviceName,
  status,
}) => {
  return (
    <Card className="mt-5">
      <CardHeader>
        <CardTitle>{deviceName}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-row items-center text-center gap-1">
        <Circle
          color={status === DeviceAvailability.OFFLINE ? 'red' : 'green'}
          fill={status === DeviceAvailability.OFFLINE ? 'red' : 'green'}
        />
        {status}
      </CardContent>
    </Card>
  );
};

const DevicesAvailabilityStatistics: FC<DevicesAvailabilityStatisticsProps> = ({
  fertDevices,
  sensDevices,
  startDevices,
}) => {
  return (
    <AccordionItem value="devicesAvailability">
      <AccordionTrigger>Prikaz povezanosti uredjaja</AccordionTrigger>
      <AccordionContent>
        <div className="grid gap-4 lg:grid-cols-4 xl:gap-8 xl:grid-cols-7 md:grid-cols-1">
          {fertDevices
            .sort(compareByNameLocale)
            .map((device: FERTDevice, idx) => (
              <DeviceAvailabilityCard
                deviceName={device.name}
                status={DeviceAvailability.ONLINE}
                key={`availability-device-${device.name}-${idx + 1}`}
              />
            ))}
          {startDevices
            .sort(compareByNameLocale)
            .map((device: StartDevice, idx) => (
              <DeviceAvailabilityCard
                deviceName={device.name}
                status={DeviceAvailability.OFFLINE}
                key={`availability-device-${device.name}-${idx + 1}`}
              />
            ))}
          {sensDevices
            .sort(compareByNameLocale)
            .map((device: SensDevice, idx) => (
              <DeviceAvailabilityCard
                deviceName={device.name}
                status={DeviceAvailability.ONLINE}
                key={`availability-device-${device.name}-${idx + 1}`}
              />
            ))}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default DevicesAvailabilityStatistics;
