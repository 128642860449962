import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { CreateUserFormData } from '@/components/forms/AddUserForm.tsx';
import { EditUserFormData } from '@/components/forms/EditUserForm.tsx';
import { MassMailType } from '@/components/forms/user/MassMailForm.tsx';
import {
  addUser,
  removeDeletedUser,
  setFilteredNumberOfUsers,
  setParcelsForUser,
  setTotalNumberOfUsers,
  setUpdatedUser,
  setUsers,
  setUsersCountries,
  setUsersForDropdown,
  setUsersPlaces,
  setUsersYearsOfCreation,
} from '@/redux/users/users.slice.ts';
import {
  CreateUserResponse,
  GetParcelsForUserResponse,
  GetUserDropdownResponse,
  GetUsersCountriesResponse,
  GetUsersPlacesResponse,
  GetUsersResponse,
  GetUsersYearsOfCreationResponse,
  SendMassEmailsResponse,
  UpdateUserResponse,
} from '@/redux/users/users.types.ts';
import axiosServer from '@/services/axios.service.ts';

const getFilteredUsers = createAsyncThunk<GetUsersResponse, string>(
  'admin/get-users',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetUsersResponse>(
        `/admin/user${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setUsers(axiosResponse.data.content.fetchedUsers));
      dispatch(
        setTotalNumberOfUsers(Number(axiosResponse.data.content.totalCount))
      );
      dispatch(
        setFilteredNumberOfUsers(
          Number(axiosResponse.data.content.filteredCount)
        )
      );
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const createUser = createAsyncThunk<CreateUserResponse, CreateUserFormData>(
  'admin/create-user',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.post<CreateUserResponse>(
        '/admin/user/create-user',
        reqData
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(addUser(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getUserById = createAsyncThunk<CreateUserResponse, string>(
  'admin/get-user-by-id',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.get<CreateUserResponse>(
        `/admin/user/get-one?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const updateUser = createAsyncThunk<UpdateUserResponse, EditUserFormData>(
  'admin/edit-user',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.put<UpdateUserResponse>(
        '/admin/user/update',
        reqData
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setUpdatedUser(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getUsersForDropdown = createAsyncThunk<GetUserDropdownResponse, void>(
  'admin/get-user-by-id',
  async (_reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetUserDropdownResponse>(
        `/admin/user/get-dropdown`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setUsersForDropdown(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const deleteUser = createAsyncThunk<
  CreateUserResponse,
  { userId: string; organizationId: string }
>('admin/create-user', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.delete<CreateUserResponse>(
      `/admin/user/delete-user?userId=${reqData.userId}&organizationId=${reqData.organizationId}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(removeDeletedUser(reqData.userId));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getUsersForParcelCombobox = createAsyncThunk<
  GetUserDropdownResponse,
  void
>('admin/get-user-parcel-combobox', async (_reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetUserDropdownResponse>(
      `/admin/user/get-parcel-combobox`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setUsersForDropdown(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getParcelsForUser = createAsyncThunk<GetParcelsForUserResponse, string>(
  'admin/get-parcels-for-user',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetParcelsForUserResponse>(
        `/admin/user/get-parcels-for-user?userId=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setParcelsForUser(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getUsersCountries = createAsyncThunk<GetUsersCountriesResponse, void>(
  'admin/get-users-countries',
  async (_reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetUsersCountriesResponse>(
        `/admin/user/get-countries`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setUsersCountries(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getUsersPlaces = createAsyncThunk<GetUsersPlacesResponse, void>(
  'admin/get-users-places',
  async (_reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetUsersPlacesResponse>(
        `/admin/user/get-places`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setUsersPlaces(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getUsersYearsOfCreation = createAsyncThunk<
  GetUsersYearsOfCreationResponse,
  void
>('admin/get-users-years-of-creation', async (_reqData, { dispatch }) => {
  try {
    const axiosResponse =
      await axiosServer.get<GetUsersYearsOfCreationResponse>(
        `/admin/user/get-created-years`
      );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setUsersYearsOfCreation(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getUserForOrganization = createAsyncThunk<UpdateUserResponse, string>(
  'admin/get-user-for-organization',
  async (reqData) => {
    try {
      const axiosResponse = await axiosServer.get<UpdateUserResponse>(
        `/admin/user/get-user-for-organization?organizationId=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const sendMassMailsToUsers = createAsyncThunk<
  SendMassEmailsResponse,
  MassMailType
>('admin/send-mass-mails-to-user', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post<SendMassEmailsResponse>(
      '/admin/user/send-mass-mails',
      reqData
    );

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export {
  createUser,
  getFilteredUsers,
  getUserById,
  updateUser,
  getUsersForDropdown,
  deleteUser,
  getUsersForParcelCombobox,
  getParcelsForUser,
  getUsersCountries,
  getUsersPlaces,
  getUsersYearsOfCreation,
  getUserForOrganization,
  sendMassMailsToUsers,
};
