import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import DidTableRow from '@/components/layout/did/DidTableRow.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { Table, TableBody } from '@/components/ui/table.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getDids as getDidsAction } from '@/redux/did/did.actions.ts';
import { getDids } from '@/redux/did/did.selectors.ts';
import { AllDidsResponse } from '@/redux/did/did.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';

type DidTableProps = {
  inputText?: string;
  selectedOccupied?: string;
  onColumnClick?: (column: string) => void;
  sortState?: { enabledColumns: string[]; activeColumn: string };
  query?: string;
};

const DidTable: FC<DidTableProps> = ({
  inputText,
  selectedOccupied,
  onColumnClick,
  sortState,
  query,
}) => {
  const [dids, setDids] = useState(useAppSelector(getDids));
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const loader = useRef(null);
  const [pageCounter, setPageCounter] = useState<number>(2);
  const observer = useRef<IntersectionObserver | null>(null);

  const didTableHeader: TableHeaderType[] = [
    {
      id: 'did',
      text: 'DID',
      className:
        'text-xs-under-700 sticky top-0 max-sm:top-14 z-[100] bg-white',
      buttonStyle: 'text-xs-under-700',
      isSortDisabled: true,
    },
    {
      id: 'device',
      text: 'Uredjaj',
      className: 'max-xl:hidden sticky top-0 max-sm:top-14 z-[100] bg-white',
      isSortDisabled: !sortState.enabledColumns.includes('device'),
    },
    {
      id: 'revision',
      text: 'Revision',
      className: 'max-lg:hidden sticky top-0 max-sm:top-14 z-[100] bg-white',
      isSortDisabled: !sortState.enabledColumns.includes('revision'),
    },
    {
      id: 'dsn',
      text: 'Serijski broj',
      className:
        'text-xs-under-700 sticky top-0 max-sm:top-14 z-[100] bg-white',
      buttonStyle: 'text-xs-under-700',
      isSortDisabled: !sortState.enabledColumns.includes('dsn'),
    },
    {
      id: 'occupied',
      text: 'Zauzetost',
      className:
        'text-xs-under-700 sticky top-0 max-sm:top-14 z-[100] bg-white',
      buttonStyle: 'text-xs-under-700',
      isSortDisabled: true,
    },
  ];

  const callGetDids = useCallback(
    async (page: number) => {
      const handleErrorResponseCallback = (response: AllDidsResponse) => {
        if (response.error.removeUser) {
          localStorage.removeItem('token');
          localStorage.removeItem('name');
          dispatch(logoutState());
          dispatch(setName(''));
          router('/login');
          return;
        }

        toast.error(response.error.message);
      };

      const response = await dispatch(
        getDidsAction(`?page=${page}&count=20${query}`)
      ).unwrap();

      if (!response.success) {
        handleErrorResponseCallback(response);
        return;
      }

      // eslint-disable-next-line consistent-return
      return response;
    },
    [dispatch, router, query]
  );

  const handleObserver = useCallback(
    async (entries) => {
      const target = entries[0];
      if (!target.isIntersecting) return;

      const response = await callGetDids(pageCounter);

      if (response?.content.length === 0 && observer.current) {
        observer.current.disconnect();
        return;
      }

      setDids((prevDids) => [...prevDids, ...response.content]);
      setPageCounter((prevPage) => prevPage + 1);
    },
    [callGetDids, pageCounter]
  );

  useEffect(() => {
    observer.current = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });
    if (loader.current && observer.current) {
      observer.current.observe(loader.current);
    }
    return () => {
      if (loader.current && observer.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.current.unobserve(loader.current);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    const fetchDids = async () => {
      setDids([]);
      setPageCounter(2);

      const response = await callGetDids(1);

      setDids(response.content);
    };

    fetchDids();
  }, [inputText, dispatch, selectedOccupied, callGetDids]);

  return (
    <Card x-chunk="dashboard-did">
      <CardHeader>
        <CardTitle>DID</CardTitle>
      </CardHeader>
      <CardContent>
        <Table>
          <CustomTableHeader
            tableName="users"
            tableHeaders={didTableHeader}
            onHeaderCellClick={onColumnClick}
          />
          <TableBody>
            {dids.map((did, index) => (
              <DidTableRow
                key={`did-table-data-${index + 1}`}
                did={{
                  ...did,
                  occupied: did.occupied.toString(),
                }}
              />
            ))}
          </TableBody>
        </Table>
        <div ref={loader} />
      </CardContent>
    </Card>
  );
};
export default DidTable;
