import { FC } from 'react';

import { Button } from '@/components/ui/button.tsx';
import { deviceTypes } from '@/redux/device/devices.types.ts';

type ButtonProps = {
  onFERTClick: () => void;
  onStartClick: () => void;
  onSENSClick: () => void;
  selectedDeviceType: string;
};
const SelectTypeButton: FC<ButtonProps> = ({
  onFERTClick,
  onStartClick,
  onSENSClick,
  selectedDeviceType,
}) => {
  return (
    <div className="max-sm:flex max-sm:flex-col">
      <Button
        onClick={onFERTClick}
        className={`${selectedDeviceType === deviceTypes.FERT ? 'bg-[#2b90d9] text-white' : 'bg-white text-black'} py-3 px-5 mr-3`}
      >
        Smart FERT
      </Button>
      <Button
        onClick={onStartClick}
        className={`${selectedDeviceType === deviceTypes.START ? 'bg-[#2b90d9] text-white' : 'bg-white text-black'} py-3 px-5 mr-3`}
      >
        Smart START
      </Button>
      <Button
        onClick={onSENSClick}
        className={`${selectedDeviceType === deviceTypes.SENS ? 'bg-[#2b90d9] text-white' : 'bg-white text-black'} py-3 px-5 mr-3`}
      >
        Smart SENS
      </Button>
    </div>
  );
};
export default SelectTypeButton;
