import { FC } from 'react';

import CustomTableHeader, {
  TableHeaderType,
} from '@/components/layout/CustomTableHeader.tsx';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@/components/ui/table.tsx';

const irrigationInfoTableHeader: TableHeaderType[] = [
  {
    id: 'type',
    text: '',
    className: '',
    isSortDisabled: true,
  },
  {
    id: 'water-usage-from-begining',
    text: 'Od trenutka puštanja u rad',
    className: '',
    isSortDisabled: true,
  },
  {
    id: 'water-usage-for-season',
    text: 'Za ovu sezonu',
    className: '',
    isSortDisabled: true,
  },
  {
    id: 'water-usage-for-month',
    text: 'Poslednjih mesec dana',
    className: '',
    isSortDisabled: true,
  },
  {
    id: 'water-usage-for-7-days',
    text: 'Poslednjih sedam dana',
    className: '',
    isSortDisabled: true,
  },
  {
    id: 'water-usage-for-3-days',
    text: 'Poslednja tri dana',
    className: '',
    isSortDisabled: true,
  },
  {
    id: 'water-usage-for-today',
    text: 'Na današnji dan',
    className: '',
    isSortDisabled: true,
  },
];

const IrrigationInfoStatistics: FC = () => {
  return (
    <AccordionItem value="irrigationInfo">
      <AccordionTrigger>Podaci o navodnjavanju parcele</AccordionTrigger>
      <AccordionContent>
        <div className="">
          <Table>
            <CustomTableHeader
              tableName="water-usage"
              tableHeaders={irrigationInfoTableHeader}
            />
            <TableBody>
              <TableRow>
                <TableCell>Ukupno Utrošeno vode [m3]</TableCell>
                <TableCell>10.000</TableCell>
                <TableCell>3.000</TableCell>
                <TableCell>1.500</TableCell>
                <TableCell>450</TableCell>
                <TableCell>15,5</TableCell>
                <TableCell>3,7</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Broj Navodnjavanja</TableCell>
                <TableCell>300</TableCell>
                <TableCell>100</TableCell>
                <TableCell>50</TableCell>
                <TableCell>15</TableCell>
                <TableCell>6</TableCell>
                <TableCell>2</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default IrrigationInfoStatistics;
