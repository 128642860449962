import { Search } from 'lucide-react';
import { ChangeEvent, FC } from 'react';

import BreadcrumbWrapper from '@/components/layout/BreadcrumbWrapper.tsx';
import MobileDrawerNavigation from '@/components/layout/MobileDrawerNavigation.tsx';
import UserDropdownMenu from '@/components/layout/UserDropdownMenu.tsx';
import { Input } from '@/components/ui/input.tsx';

type PageHeaderProps = {
  shouldShowInput: boolean;
  handleChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
};

const PageHeader: FC<PageHeaderProps> = ({ shouldShowInput, handleChange }) => {
  if (shouldShowInput && !handleChange) {
    return null;
  }

  return (
    <header className="sticky top-0 z-[1] flex h-14 items-center gap-4 border-b bg-white px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6 justify-between">
      <MobileDrawerNavigation />
      <BreadcrumbWrapper />
      <div className="flex flex-row gap-1 items-center max-sm:w-full justify-end">
        {shouldShowInput ? (
          <div className="relative ml-auto flex-1 md:grow-0">
            <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              type="pretraga"
              placeholder="Pretraga..."
              className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px] min-w-[200px] max-md:min-w-[150px]"
              onChange={handleChange}
            />
          </div>
        ) : null}
        <UserDropdownMenu />
      </div>
    </header>
  );
};

export default PageHeader;
