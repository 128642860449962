import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormSwitch from '@/components/controls/CustomFormSwitch.tsx';

type CreateParcelSensorFormProps = {
  form: UseFormReturn<any>;
};

const CreateParcelSensorForm: FC<CreateParcelSensorFormProps> = ({ form }) => {
  return (
    <div className="flex flex-col">
      <CustomFormSwitch
        customForm={form}
        name="humiditySensor"
        labelText="Senzor vlažnosti"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="secondHumiditySensor"
        labelText="Drugi senzor vlažnosti"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="supplementMixer"
        labelText="Mikser prihrane"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="supplement"
        labelText="Prihrana"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="voltage"
        labelText="Napon"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="rawSensorValues"
        labelText="Neobradjene vrednosti senzora"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2 sm:mt-3"
        labelStyle="max-md:w-full text-sm"
      />
    </div>
  );
};

export default CreateParcelSensorForm;
