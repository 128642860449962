import { FC } from 'react';

import AddFruitTypeForm from '@/components/forms/AddFruitTypeForm.tsx';
import FruitSortContent from '@/components/layout/fruits/FruitSortContent.tsx';
import FruitTypesSection from '@/components/layout/fruits/FruitTypesSection.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';

const FruitTypesContent: FC = () => {
  return (
    <div className="flex flex-row max-xl:flex-col items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
      <div className="flex flex-col w-[50%] max-xl:w-full">
        <AddFruitTypeForm />
        <Card className="w-full mt-5">
          <CardHeader>
            <CardTitle>Sorte</CardTitle>
          </CardHeader>
          <CardContent className="p-0">
            <FruitSortContent />
          </CardContent>
        </Card>
      </div>
      <div className="flex w-[50%] max-xl:w-full">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>Vrste</CardTitle>
          </CardHeader>
          <CardContent>
            <FruitTypesSection />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default FruitTypesContent;
