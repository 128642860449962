import {
  FetchOrganization,
  ParcelForOrganization,
} from '@/redux/organizations/organizations.types.ts';
import { RootState } from '@/redux/store.ts';

export const getOrganizations = (state: RootState): FetchOrganization[] =>
  state.reducer.organizations.organizations || [];

export const getParcelsForOrganization = (
  state: RootState
): ParcelForOrganization[] =>
  state.reducer.organizations.parcelsForOrganization || [];
