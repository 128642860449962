import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormRadioGroup from '@/components/controls/CustomFormRadioGroup.tsx';
import { itemsForRadioGroup } from '@/utils/device.util.ts';

type StartDeviceSensorsFormProps = {
  form: UseFormReturn<any, any, any>;
};
const StartDeviceSensorsForm: FC<StartDeviceSensorsFormProps> = ({ form }) => {
  return (
    <>
      <div className="w-full flex flex-row max-md:flex-col gap-2">
        <CustomFormRadioGroup
          customForm={form}
          name="supplementMixer"
          labelText="Prihrana ili Mikser"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="flowMeter"
          labelText="Protokomer"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3 pb-5">
        <CustomFormRadioGroup
          customForm={form}
          name="notificationForWrongFlowMeter"
          labelText="Notifikacija za nedetektovanje protoka"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="pressureSensor"
          labelText="Senzor Pritiska u sistemu za navodnjavanje"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
    </>
  );
};

export default StartDeviceSensorsForm;
