export const languages = [
  {
    value: 'SRB',
    label: 'Srpski',
  },
  {
    value: 'ENG',
    label: 'Engleski',
  },
  {
    value: 'CRO',
    label: 'Hrvatski',
  },
  {
    value: 'BIH',
    label: 'Bosanski',
  },
  {
    value: 'ALB',
    label: 'Albanski',
  },
  {
    value: 'DE',
    label: 'Nemački',
  },
  {
    value: 'SK',
    label: 'Slovački',
  },
  {
    value: 'ARAB',
    label: 'Arapski',
  },
  {
    value: 'ESP',
    label: 'Španski',
  },
  {
    value: 'POR',
    label: 'Portugalski',
  },
  {
    value: 'FRA',
    label: 'Francuski',
  },
  {
    value: 'ROM',
    label: 'Rumunski',
  },
  {
    value: 'RUS',
    label: 'Ruski',
  },
];
