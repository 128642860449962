import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import EditParcelForm from '@/components/forms/EditParcelForm.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { ParcelWithDevices } from '@/redux/parcels/parcels.types.ts';
import { getUserForOrganization } from '@/redux/users/users.actions.ts';
import { UpdateUserResponse } from '@/redux/users/users.types.ts';

type ParcelInfoCardProps = {
  parcelForEdit: ParcelWithDevices;
};

const ParcelInfoCard: FC<ParcelInfoCardProps> = ({ parcelForEdit }) => {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const onDevicesClick = () => {
    router(`/devices/${parcelForEdit.id}`);
  };

  const handleErrorResponse = (response: UpdateUserResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onUserClick = async () => {
    const response = await dispatch(
      getUserForOrganization(parcelForEdit.organizationId)
    ).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    router(`/users/edit/${response.content.id}`);
  };

  return (
    <Card x-chunk="edit-parcel-cart">
      <CardHeader className="flex flex-row justify-between items-center">
        <CardTitle>Izmeni parcelu</CardTitle>
        <div className="flex flex-row items-center gap-2">
          <Button
            type="button"
            className="outline outline-[#339c45] text-[#339c45] px-2 py-2"
            onClick={onUserClick}
          >
            Korisnik
          </Button>
          <Button
            type="button"
            className="outline outline-[#2b90d9] text-[#2b90d9] px-2 py-2"
            onClick={onDevicesClick}
          >
            Uredjaji
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <EditParcelForm existingParcel={parcelForEdit} />
      </CardContent>
    </Card>
  );
};

export default ParcelInfoCard;
