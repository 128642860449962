import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormSwitch from '@/components/controls/CustomFormSwitch.tsx';

type EditParcelAdditionalSensorFormProps = {
  form: UseFormReturn<any>;
};

const EditParcelAdditionalSensorForm: FC<
  EditParcelAdditionalSensorFormProps
> = ({ form }) => {
  return (
    <>
      <CustomFormSwitch
        customForm={form}
        name="numberOfIrrigation"
        labelText="Broj navodnjavanja"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="airHumidity"
        labelText="Vlažnost vazduha"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="pumpSensor"
        labelText="Senzor pumpe"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="numberOfZonesSwitch"
        labelText="Broj zona"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="airTemperature"
        labelText="Temperatura vazduha"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="soilTemperatureSensor"
        labelText="Senzor temperature zemljišta"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="soilSalinity"
        labelText="Salinitet zemljišta"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="flowMeter"
        labelText="Protokomer"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="waterLevelSensor"
        labelText="Senzor nivoa vode"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="pHSensor"
        labelText="pH senzor"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
      <CustomFormSwitch
        customForm={form}
        name="moreSENSSensors"
        labelText="Više SENS senzora"
        formItemStyle="flex flex-col pt-5 max-md:py-5 gap-2"
        labelStyle="max-md:w-full text-sm"
      />
    </>
  );
};

export default EditParcelAdditionalSensorForm;
