import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormRadioGroup from '@/components/controls/CustomFormRadioGroup.tsx';
import { itemsForRadioGroup } from '@/utils/device.util.ts';

type FERTDeviceSensorsFormProps = {
  form: UseFormReturn<any, any, any>;
};

const FERTDeviceSensorsForm: FC<FERTDeviceSensorsFormProps> = ({ form }) => {
  return (
    <>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-5">
        <CustomFormRadioGroup
          customForm={form}
          name="ecSensor"
          labelText="EC prihrane"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="pHWater"
          labelText="pH prihrane"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3">
        <CustomFormRadioGroup
          customForm={form}
          name="flowMeter"
          labelText="Protokomer"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="pressureSensor"
          labelText="Pritisak u sistemu"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 pb-5 mt-3">
        <CustomFormRadioGroup
          customForm={form}
          name="notificationForWrongFlowMeter"
          labelText="Notifikacija za nedetektovanje protoka"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="waterLevelSensor"
          labelText="Nivo Vode"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
    </>
  );
};

export default FERTDeviceSensorsForm;
