import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { CreateFERTDeviceFormData } from '@/components/forms/CreateFERTDeviceForm.tsx';
import { CreateSensDeviceFormData } from '@/components/forms/CreateSensDeviceForm.tsx';
import { CreateStartDeviceFormData } from '@/components/forms/CreateStartDeviceForm.tsx';
import { EditFERTDeviceFormData } from '@/components/forms/EditFERTDeviceForm.tsx';
import { EditSensDeviceFormData } from '@/components/forms/EditSensDeviceForm.tsx';
import { EditStartDeviceFormData } from '@/components/forms/EditStartDeviceForm.tsx';
import {
  patchFERTDeliveryState,
  patchSENSDeliveryState,
  patchSTARTDeliveryState,
  removeDeletedDevice,
  setAvailableZones,
  setDevices,
  setDevicesRevisions,
  setFERTDeviceForEdit,
  setParcelUserInfo,
  setSensDeviceForEdit,
  setStartDeviceForEdit,
  setUserDevices,
} from '@/redux/device/devices.slice.ts';
import {
  CreateDeviceResponse,
  FERTDevice,
  GetAvailableZonesResponse,
  GetDevicesForUserResponse,
  GetDevicesResponse,
  GetDevicesRevisionsResponse,
  GetFERTDeviceResponse,
  GetSensDeviceResponse,
  GetStartDeviceResponse,
  PatchFERTRequest,
  PatchSENSRequest,
  PatchSTARTRequest,
  SensDevice,
  StartDevice,
} from '@/redux/device/devices.types.ts';
import axiosServer from '@/services/axios.service.ts';
import {
  transformEditFERTDeviceData,
  transformEditSensDeviceData,
  transformEditStartDeviceData,
  transformFERTDeviceData,
  transformSensDeviceData,
  transformStartDeviceData,
} from '@/utils/device.util.ts';

const getAvailableZonesForParcel = createAsyncThunk<
  GetAvailableZonesResponse,
  string
>('admin/get-available-zones-device', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetAvailableZonesResponse>(
      `/admin/device/available-zones?parcelId=${reqData}`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setAvailableZones(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const createFERTDevice = createAsyncThunk<
  CreateDeviceResponse,
  CreateFERTDeviceFormData
>('admin/create-fert-device', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post<CreateDeviceResponse>(
      '/admin/device/create-fert-device',
      transformFERTDeviceData(reqData)
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const createStartDevice = createAsyncThunk<
  CreateDeviceResponse,
  CreateStartDeviceFormData
>('admin/create-start-device', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post<CreateDeviceResponse>(
      '/admin/device/create-start-device',
      transformStartDeviceData(reqData)
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getAllDevicesForParcel = createAsyncThunk<GetDevicesResponse, string>(
  'admin/get-devices-for-parcel',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetDevicesResponse>(
        `/admin/device/devices-for-parcel?parcelId=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setParcelUserInfo(axiosResponse.data.content.userInfo));
      dispatch(setDevices(axiosResponse.data.content.devices));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getStartDeviceById = createAsyncThunk<GetStartDeviceResponse, string>(
  'admin/get-start-device-by-id',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetStartDeviceResponse>(
        `/admin/device/start-device-by-id?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setStartDeviceForEdit(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const updateStartDevice = createAsyncThunk<
  CreateDeviceResponse,
  {
    device: StartDevice;
    deviceToEdit: EditStartDeviceFormData;
  }
>('admin/update-start-device', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post<CreateDeviceResponse>(
      '/admin/device/update-start-device',
      transformEditStartDeviceData(reqData)
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getFERTDeviceById = createAsyncThunk<GetFERTDeviceResponse, string>(
  'admin/get-fert-device-by-id',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetFERTDeviceResponse>(
        `/admin/device/fert-device-by-id?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setFERTDeviceForEdit(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const updateFERTDevice = createAsyncThunk<
  CreateDeviceResponse,
  {
    device: FERTDevice;
    deviceToEdit: EditFERTDeviceFormData;
  }
>('admin/update-fert-device', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post<CreateDeviceResponse>(
      '/admin/device/update-fert-device',
      transformEditFERTDeviceData(reqData)
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const deleteFERTDeviceById = createAsyncThunk<CreateDeviceResponse, string>(
  'admin/delete-fert-device',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.delete<CreateDeviceResponse>(
        `/admin/device/delete-fert-device?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(removeDeletedDevice(reqData));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const deleteStartDeviceById = createAsyncThunk<CreateDeviceResponse, string>(
  'admin/delete-start-device',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.delete<CreateDeviceResponse>(
        `/admin/device/delete-start-device?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(removeDeletedDevice(reqData));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const createSensDevice = createAsyncThunk<
  CreateDeviceResponse,
  CreateSensDeviceFormData
>('admin/create-sens-device', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post<CreateDeviceResponse>(
      '/admin/device/create-sens-device',
      transformSensDeviceData(reqData)
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const getSensDeviceById = createAsyncThunk<GetSensDeviceResponse, string>(
  'admin/get-sens-device-by-id',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetSensDeviceResponse>(
        `/admin/device/sens-device-by-id?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setSensDeviceForEdit(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const updateSensDevice = createAsyncThunk<
  CreateDeviceResponse,
  {
    device: SensDevice;
    deviceToEdit: EditSensDeviceFormData;
  }
>('admin/update-sens-device', async (reqData) => {
  try {
    const axiosResponse = await axiosServer.post<CreateDeviceResponse>(
      '/admin/device/update-sens-device',
      transformEditSensDeviceData(reqData)
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const deleteSensDeviceById = createAsyncThunk<CreateDeviceResponse, string>(
  'admin/delete-sens-device',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.delete<CreateDeviceResponse>(
        `/admin/device/delete-sens-device?id=${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(removeDeletedDevice(reqData));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getDevicesForUser = createAsyncThunk<GetDevicesForUserResponse, string>(
  'admin/devices-by-user-id',
  async (reqData, { dispatch }) => {
    try {
      const axiosResponse = await axiosServer.get<GetDevicesForUserResponse>(
        `/admin/device/devices-by-user-id${reqData}`
      );
      if (!axiosResponse.data.success) {
        return axiosResponse.data;
      }

      dispatch(setUserDevices(axiosResponse.data.content));
      return axiosResponse.data;
    } catch (error) {
      Sentry.captureException(error);
      return error;
    }
  }
);

const getAllDevicesRevisions = createAsyncThunk<
  GetDevicesRevisionsResponse,
  string
>('admin/revisions', async (_reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.get<GetDevicesRevisionsResponse>(
      `/admin/device/revisions`
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(setDevicesRevisions(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const pathFERTDelivery = createAsyncThunk<
  GetFERTDeviceResponse,
  PatchFERTRequest
>('admin/patch-fert-device', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.patch<GetFERTDeviceResponse>(
      '/admin/device/patch-fert-device',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(patchFERTDeliveryState(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const pathSTARTDelivery = createAsyncThunk<
  GetStartDeviceResponse,
  PatchSTARTRequest
>('admin/patch-start-device', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.patch<GetStartDeviceResponse>(
      '/admin/device/patch-start-device',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(patchSTARTDeliveryState(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

const patchSENSDelivery = createAsyncThunk<
  GetSensDeviceResponse,
  PatchSENSRequest
>('admin/patch-sens-device', async (reqData, { dispatch }) => {
  try {
    const axiosResponse = await axiosServer.patch<GetSensDeviceResponse>(
      '/admin/device/patch-sens-device',
      reqData
    );
    if (!axiosResponse.data.success) {
      return axiosResponse.data;
    }

    dispatch(patchSENSDeliveryState(axiosResponse.data.content));
    return axiosResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    return error;
  }
});

export {
  getAvailableZonesForParcel,
  createFERTDevice,
  getAllDevicesForParcel,
  createStartDevice,
  getStartDeviceById,
  updateStartDevice,
  getFERTDeviceById,
  updateFERTDevice,
  deleteFERTDeviceById,
  deleteStartDeviceById,
  createSensDevice,
  getSensDeviceById,
  updateSensDevice,
  deleteSensDeviceById,
  getDevicesForUser,
  getAllDevicesRevisions,
  pathFERTDelivery,
  pathSTARTDelivery,
  patchSENSDelivery,
};
