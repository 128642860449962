import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button.tsx';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { getName } from '@/redux/auth/auth.selectors.ts';
import { login, logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getFromLocalStorage } from '@/services/local.storage.ts';

const UserDropdownMenu: FC = () => {
  const dispatch = useAppDispatch();
  const name = useAppSelector(getName);
  const router = useNavigate();

  useEffect(() => {
    if (getFromLocalStorage('token')) {
      dispatch(login());
      dispatch(
        setName({
          name: getFromLocalStorage('name'),
        })
      );
    }
  }, [dispatch]);

  const onLogoutClick = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
    dispatch(logoutState());
    dispatch(setName(''));
    router('/login');
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="overflow-hidden rounded-full cursor-pointer w-[40px] h-[40px]"
        >
          <img
            src="/avatar-1.jpg"
            width={50}
            height={50}
            alt="avatar"
            className="w-full h-full"
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{name}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem className="cursor-pointer" onClick={onLogoutClick}>
          Odjava
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserDropdownMenu;
