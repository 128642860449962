import { FC } from 'react';

import AddSortForm from '@/components/forms/AddSortForm.tsx';
import FruitSortSection from '@/components/layout/fruits/FruitSortSection.tsx';

const FruitSortContent: FC = () => {
  return (
    <div className="flex items-start">
      <div className="bg-white w-full flex flex-col md:gap-5">
        <div className="w-full px-5">
          <div className="flex flex-row md:gap-5 border-b-2 max-xl:flex-col">
            <div className="w-[50%] max-xl:w-full">
              <AddSortForm />
            </div>
            <div className="w-[50%] max-md:hidden" />
          </div>
        </div>
        <FruitSortSection />
      </div>
    </div>
  );
};

export default FruitSortContent;
