import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormCheckbox from '@/components/controls/CustomFormCheckbox.tsx';
import CustomFormCombobox, {
  ComboboxItemType,
} from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import { countries } from '@/utils/countryUtil.ts';

type BillingAddressFormProps = {
  form: UseFormReturn<any>;
  isFormShown: boolean;
};

const BillingAddressForm: FC<BillingAddressFormProps> = ({
  form,
  isFormShown,
}) => {
  const countriesComboboxContent: ComboboxItemType[] = countries.map(
    (country) => {
      return {
        value: country,
        label: country,
      };
    }
  );

  return (
    <div className="mb-5">
      <CustomFormCheckbox
        form={form}
        name="differentBillingAddress"
        labelText="Da li se adresa za dostavu računa razlikuje?"
        formItemStyle="flex flex-col gap-2 mt-5 px-2"
        checkboxStyle="w-5 h-5 data-[state=checked]:bg-[#007bff] data-[state=checked]:text-neutral-50"
      />
      {isFormShown && (
        <>
          <CustomFormInput
            customForm={form}
            name="billingAddress"
            labelText="Adresa dostave"
            formItemStyle="flex flex-col w-full pb-5 mt-5 px-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
          <CustomFormInput
            customForm={form}
            name="billingPlace"
            labelText="Mesto dostave"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full sm:w-[300px]"
            divStyle="max-md:w-full md:w-[500px]"
            type="text"
          />
          <CustomFormCombobox
            customForm={form}
            name="billingCountry"
            labelText="Država dostave"
            formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
            labelStyle="max-sm:w-full w-[300px]"
            buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
            items={countriesComboboxContent}
            placeholder="Odaberite državu"
          />
        </>
      )}
    </div>
  );
};

export default BillingAddressForm;
