import { FC } from 'react';

import ParcelInfoCard from '@/components/layout/parcels/ParcelInfoCard.tsx';
import ParcelsStatisticCard from '@/components/layout/parcels/ParcelsStatisticCard.tsx';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs.tsx';
import { ParcelWithDevices } from '@/redux/parcels/parcels.types.ts';

enum EditParcelTab {
  INFO = 'info',
  STATISTICS = 'statistics',
}

type EditParcelTabsProps = {
  parcelForEdit: ParcelWithDevices;
};

const EditParcelTabs: FC<EditParcelTabsProps> = ({ parcelForEdit }) => {
  return (
    <Tabs defaultValue={EditParcelTab.INFO}>
      <TabsList>
        <TabsTrigger
          value={EditParcelTab.INFO}
          className="data-[state=active]:bg-[#2a90d9] data-[state=active]:text-white"
        >
          Info
        </TabsTrigger>
        <TabsTrigger
          value={EditParcelTab.STATISTICS}
          className="data-[state=active]:bg-[#2a90d9] data-[state=active]:text-white"
        >
          Statistika
        </TabsTrigger>
      </TabsList>
      <TabsContent value={EditParcelTab.INFO}>
        <ParcelInfoCard parcelForEdit={parcelForEdit} />
      </TabsContent>
      <TabsContent value={EditParcelTab.STATISTICS}>
        <ParcelsStatisticCard parcel={parcelForEdit} />
      </TabsContent>
    </Tabs>
  );
};

export default EditParcelTabs;
