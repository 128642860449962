import { PlusCircle } from 'lucide-react';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/ui/button.tsx';

type AddNewIconProps = {
  link: string;
  text: string;
};

const AddNewIcon: FC<AddNewIconProps> = ({ link, text }) => {
  const router = useNavigate();

  const onAddNewClick = () => {
    router(link);
  };

  return (
    <div className="flex items-center">
      <div className="ml-auto flex items-center gap-2">
        <Button size="sm" className="h-8 gap-1" onClick={onAddNewClick}>
          <PlusCircle className="h-8 w-8 text-[#2b90d9]" />
          <span className="sm:whitespace-nowrap text-[#2b90d9]">{text}</span>
        </Button>
      </div>
    </div>
  );
};

export default AddNewIcon;
