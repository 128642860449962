import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';

type CreateParcelBoundaryFormProps = {
  form: UseFormReturn<any>;
};

const CreateParcelBoundaryForm: FC<CreateParcelBoundaryFormProps> = ({
  form,
}) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="boundaryForDryLand"
        labelText="Granica - suvo zemljište"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        min="0"
      />
      <CustomFormInput
        customForm={form}
        name="boundaryForWetlands"
        labelText="Granica - vlažno zemljište"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        min="0"
      />
    </>
  );
};

export default CreateParcelBoundaryForm;
