import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormInput from '@/components/controls/CustomFormInput.tsx';

type FERTDeviceFactorFormProps = {
  form: UseFormReturn<any>;
};

const FERTDeviceFactorForm: FC<FERTDeviceFactorFormProps> = ({ form }) => {
  return (
    <>
      <CustomFormInput
        customForm={form}
        name="pHFactorK"
        labelText="pH faktor K"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        inputStyle="remove-arrow"
      />
      <CustomFormInput
        customForm={form}
        name="pHFactorM"
        labelText="pH faktor M"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        inputStyle="remove-arrow"
      />
      <CustomFormInput
        customForm={form}
        name="ecFactorK"
        labelText="Kalibracioni koeficijent K za EC"
        formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
        labelStyle="max-sm:w-full sm:w-[300px]"
        divStyle="max-md:w-full md:w-[500px]"
        type="number"
        inputStyle="remove-arrow"
      />
    </>
  );
};

export default FERTDeviceFactorForm;
