import debounce from 'lodash/debounce';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DidsFilterSheet from '@/components/layout/did/DidsFilterSheet.tsx';
import DidTable from '@/components/layout/did/DidTable.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getAllDevicesRevisions } from '@/redux/device/devices.actions.ts';
import { GetDevicesRevisionsResponse } from '@/redux/device/devices.types.ts';
import { getDids } from '@/redux/did/did.actions.ts';
import { AllDidsResponse } from '@/redux/did/did.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type FilterObject = {
  realPage: number;
  realInputText: string;
  realSelectedOccupation: string;
  realSortColumn: string;
  realSortAsc: boolean;
  realRevisionSort?: boolean;
  realRevisionAsc?: boolean;
  realDsnSort?: boolean;
  realDsnAsc?: boolean;
};

const DidPage: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [inputText, setInputText] = useState<string>('');
  const [selectedOccupation, setSelectedOccupation] = useState<string>('');
  const [sortState, setSortState] = useState({
    enabledColumns: ['device'],
    activeColumn: '',
  });
  const [isSortAsc, setIsSortAsc] = useState<boolean>(false);
  const [sortColumn, setSortColumn] = useState<string>('device');
  const [revisionSort, setRevisionSort] = useState<boolean>(false);
  const [revisionAsc, setRevisionAsc] = useState<boolean>(false);
  const [dsnSort, setDsnSort] = useState<boolean>(false);
  const [dsnAsc, setDsnAsc] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const createQueryParams = (filterObject: FilterObject) => {
    const {
      realPage,
      realInputText,
      realSelectedOccupation,
      realSortColumn,
      realSortAsc,
      realRevisionAsc,
      realRevisionSort,
      realDsnAsc,
      realDsnSort,
    } = filterObject;
    setQuery(
      `${realInputText ? `&inputText=${realInputText}` : ''}${realSelectedOccupation ? `&occupied=${realSelectedOccupation}` : ''}${realSortColumn ? `&sortColumn=device&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realRevisionSort ? `&revisionSort=true&revisionAsc=${realRevisionAsc ? 'ASC' : 'DESC'}` : ''}${realDsnSort ? `&dsnSort=true&dsnAsc=${realDsnAsc ? 'ASC' : 'DESC'}` : ''}`
    );
    return `?page=${realPage}&count=20${realInputText ? `&inputText=${realInputText}` : ''}${realSelectedOccupation ? `&occupied=${realSelectedOccupation}` : ''}${realSortColumn ? `&sortColumn=device&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realRevisionSort ? `&revisionSort=true&revisionAsc=${realRevisionAsc ? 'ASC' : 'DESC'}` : ''}${realDsnSort ? `&dsnSort=true&dsnAsc=${realDsnAsc ? 'ASC' : 'DESC'}` : ''}`;
  };

  const callFetchDids = useCallback(
    async (queryParams: string) => {
      return dispatch(getDids(queryParams)).unwrap();
    },
    [dispatch]
  );

  const handleErrorResponse = (
    response: AllDidsResponse | GetDevicesRevisionsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const handleSort = async (columnId: string) => {
    if (!sortState.enabledColumns.includes(columnId)) return;

    const determineAdditionalColumns = (
      id: string,
      enabledColumns: string[]
    ) => {
      const dependencies = {
        device: 'revision',
        revision: 'dsn',
      };

      const requiredColumn = dependencies[id];
      if (requiredColumn && !enabledColumns.includes(requiredColumn)) {
        return [...enabledColumns, requiredColumn];
      }

      return enabledColumns;
    };

    setSortState((prev) => ({
      ...prev,
      activeColumn: columnId,
      enabledColumns: determineAdditionalColumns(columnId, prev.enabledColumns),
    }));

    if (columnId === 'revision') {
      setRevisionSort(true);
      setRevisionAsc(!revisionAsc);
    }

    if (columnId === 'dsn') {
      setDsnSort(true);
      setDsnAsc(!dsnAsc);
    }

    if (columnId === 'device') {
      setIsSortAsc(!isSortAsc);
    }

    setSortColumn(columnId);
  };

  useEffect(() => {
    const filterObject: FilterObject = {
      realPage: page,
      realInputText: inputText,
      realSelectedOccupation: selectedOccupation,
      realSortAsc: isSortAsc,
      realSortColumn: sortColumn,
      realRevisionAsc: revisionAsc,
      realRevisionSort: revisionSort,
      realDsnAsc: dsnAsc,
      realDsnSort: dsnSort,
    };
    const queryParams = createQueryParams(filterObject);
    callFetchDids(queryParams);
  }, [
    page,
    inputText,
    selectedOccupation,
    sortColumn,
    isSortAsc,
    revisionAsc,
    revisionSort,
    dsnAsc,
    dsnSort,
    callFetchDids,
  ]);

  const fetchDids = async () => {
    const filterObject: FilterObject = {
      realPage: page,
      realInputText: inputText,
      realSelectedOccupation: selectedOccupation,
      realSortAsc: isSortAsc,
      realSortColumn: sortColumn,
      realRevisionAsc: revisionAsc,
      realRevisionSort: revisionSort,
      realDsnAsc: dsnAsc,
      realDsnSort: dsnSort,
    };
    const queryParams = createQueryParams(filterObject);
    const response = await callFetchDids(queryParams);
    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  useEffect(() => {
    const fetchAllDevicesRevisions = async () => {
      // @ts-ignore
      const response = await dispatch(getAllDevicesRevisions()).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchAllDevicesRevisions();
    fetchDids();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDebounceCall = async (value: string) => {
    setInputText(value);
    setPage(1);
    const filterObject: FilterObject = {
      realPage: 1,
      realInputText: value,
      realSelectedOccupation: selectedOccupation,
      realSortAsc: isSortAsc,
      realSortColumn: sortColumn,
      realRevisionAsc: revisionAsc,
      realRevisionSort: revisionSort,
      realDsnAsc: dsnAsc,
      realDsnSort: dsnSort,
    };
    const queryParams = createQueryParams(filterObject);
    await callFetchDids(queryParams);
  };

  const debouncedChangeHandler = debounce((value: string) => {
    onDebounceCall(value);
  }, 500);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    debouncedChangeHandler(value);
  };

  const filterUpdateToChange: Record<
    string,
    (value: ((prevState: string) => string) | string) => void
  > = {
    realSelectedOccupation: setSelectedOccupation,
  };

  const onFilterChange = async (filter: string, value: string) => {
    filterUpdateToChange[filter](value);
    setPage(1);
    const filterObject: FilterObject = {
      realPage: 1,
      realInputText: inputText,
      realSelectedOccupation: selectedOccupation,
      realSortAsc: isSortAsc,
      realSortColumn: sortColumn,
      realRevisionAsc: revisionAsc,
      realRevisionSort: revisionSort,
      realDsnAsc: dsnAsc,
      realDsnSort: dsnSort,
    };

    filterObject[filter] = value;
    const queryParams = createQueryParams(filterObject);
    await callFetchDids(queryParams);
  };

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput handleChange={handleChange} />
        <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 sm:mt-5">
          <div className="flex items-center gap-2 justify-start">
            <DidsFilterSheet
              selectedOccupation={selectedOccupation}
              onFilterChange={onFilterChange}
            />
          </div>
          <DidTable
            inputText={inputText ? `&inputText=${inputText}` : ''}
            selectedOccupied={
              selectedOccupation ? `&occupied=${selectedOccupation}` : ''
            }
            onColumnClick={handleSort}
            sortState={sortState}
            query={query}
          />
        </div>
      </div>
    </div>
  );
};
export default DidPage;
