import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormDropDown, {
  DropDownItems,
} from '@/components/controls/CustomFormDropDown.tsx';
import { Form } from '@/components/ui/form.tsx';
import { TableCell } from '@/components/ui/table.tsx';

type TableCellDropdownFormProps = {
  form: UseFormReturn<any>;
  items: DropDownItems[];
  field: string;
  isDisabled?: boolean;
  formItemStyle?: string;
  cellStyle?: string;
  triggerStyle?: string;
};

const TableCellDropdownForm: FC<TableCellDropdownFormProps> = ({
  form,
  items,
  field,
  isDisabled,
  formItemStyle,
  cellStyle,
  triggerStyle,
}) => {
  return (
    <TableCell className={cellStyle}>
      <Form
        reset={form.reset}
        formState={form.formState}
        clearErrors={form.clearErrors}
        control={form.control}
        getFieldState={form.getFieldState}
        getValues={form.getValues}
        handleSubmit={form.handleSubmit}
        register={form.register}
        resetField={form.resetField}
        setError={form.setError}
        setFocus={form.setFocus}
        setValue={form.setValue}
        trigger={form.trigger}
        unregister={form.unregister}
        watch={form.watch}
      >
        <form>
          <CustomFormDropDown
            customForm={form}
            name={field}
            labelText=""
            items={items}
            isDisabled={isDisabled}
            formItemStyle={formItemStyle}
            triggerStyle={triggerStyle}
          />
        </form>
      </Form>
    </TableCell>
  );
};

export default TableCellDropdownForm;
