import { z } from 'zod';

export const createFruitTypeSchema = z.object({
  name: z.string().min(1, {
    message: 'Ime vrste je obavezno polje',
  }),
});

export const createFruitSortSchema = z.object({
  name: z.string().min(1, {
    message: 'Ime sorte je obavezno polje',
  }),
  fruitTypeId: z.string().min(1, {
    message: 'Niste odabrali vrstu',
  }),
});
