import { useCreateBlockNote } from '@blocknote/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormCombobox from '@/components/controls/CustomFormCombobox.tsx';
import CustomFormInput from '@/components/controls/CustomFormInput.tsx';
import CustomFormRichEditor from '@/components/controls/CustomFormRichEditor.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { sendMassMailsToUsers } from '@/redux/users/users.actions.ts';
import { SendMassEmailsResponse } from '@/redux/users/users.types.ts';
import { languages } from '@/utils/languages.ts';
import { massMailSchema } from '@/zod/mail.types.zod.ts';

export type MassMailType = {
  subject: string;
  language: string;
  emailMessage: string;
};

const MassMailForm: FC = () => {
  const editor = useCreateBlockNote();
  const massMailForm = useForm({
    resolver: zodResolver(massMailSchema),
    defaultValues: {
      subject: '',
      language: '',
      emailMessage: '',
    },
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: SendMassEmailsResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onRichTextEditorChange = async (
    onChange: (...event: any[]) => void
  ) => {
    const newValue = await editor.blocksToFullHTML(editor.document);
    onChange(newValue);
  };

  const onSubmit = async (data: MassMailType) => {
    const response = await dispatch(sendMassMailsToUsers(data)).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Poruke uspešno poslate korisnicima');
    massMailForm.reset();
    editor.removeBlocks(editor.document);
  };

  return (
    <Form
      reset={massMailForm.reset}
      formState={massMailForm.formState}
      clearErrors={massMailForm.clearErrors}
      control={massMailForm.control}
      getFieldState={massMailForm.getFieldState}
      getValues={massMailForm.getValues}
      handleSubmit={massMailForm.handleSubmit}
      register={massMailForm.register}
      resetField={massMailForm.resetField}
      setError={massMailForm.setError}
      setFocus={massMailForm.setFocus}
      setValue={massMailForm.setValue}
      trigger={massMailForm.trigger}
      unregister={massMailForm.unregister}
      watch={massMailForm.watch}
    >
      <form onSubmit={massMailForm.handleSubmit(onSubmit)}>
        <CustomFormInput
          customForm={massMailForm}
          name="subject"
          labelText="Naslov mejla"
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-sm:w-full sm:w-[300px]"
          divStyle="max-md:w-full md:w-[500px]"
          type="text"
        />
        <CustomFormCombobox
          customForm={massMailForm}
          name="language"
          labelText="Jezik na kom koristik koristi aplikaciju"
          formItemStyle="flex flex-col w-full pb-5 sm:mt-5 px-2 mt-2"
          labelStyle="max-sm:w-full w-[300px]"
          buttonStyle="max-md:w-full md:w-[500px] px-3 py-2 justify-between"
          items={languages}
          placeholder="Odaberite jezik"
        />
        <CustomFormRichEditor
          name="emailMessage"
          form={massMailForm}
          onRichTextEditorChange={onRichTextEditorChange}
          editorStyle="mt-5"
          labelText="Email poruka"
          labelStyle="max-sm:w-full w-[300px] sm:mt-5 mt-2 pb-5 px-2"
          divStyle="mb-10"
          editor={editor}
        />
        <Button className="bg-[#007bff] py-3 px-2 text-white" type="submit">
          Send Mass Mail
        </Button>
      </form>
    </Form>
  );
};

export default MassMailForm;
