import { zodResolver } from '@hookform/resolvers/zod';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import CustomFormDropDown, {
  DropDownItems,
} from '@/components/controls/CustomFormDropDown.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Form } from '@/components/ui/form.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { setDistributorForUser } from '@/redux/distributors/distributors.actions.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getDropdownUsers } from '@/redux/users/user.selectors.ts';
import { UpdateUserResponse } from '@/redux/users/users.types.ts';
import { userForDistributorSchema } from '@/zod/distributor.types.zod.ts';

export type AddUserForDistributorFormData = {
  userId: string;
  distributorId: string;
};

const AddUserForDistributorForm: FC = () => {
  const params = useParams();
  const usersForDropdown = useAppSelector(getDropdownUsers);
  const dropdownContent: DropDownItems[] = usersForDropdown.map((user) => {
    return {
      value: user.id,
      text: user.name,
    };
  });
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const form = useForm({
    resolver: zodResolver(userForDistributorSchema),
    defaultValues: {
      userId: '',
      distributorId: params.id,
    },
  });

  const handleErrorResponse = (response: UpdateUserResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onSubmit = async (data: AddUserForDistributorFormData) => {
    // @ts-ignore
    const response = await dispatch(setDistributorForUser(data)).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Uspešno dodat distributer korisniku');
    form.reset();
  };

  return (
    <Form
      reset={form.reset}
      formState={form.formState}
      clearErrors={form.clearErrors}
      control={form.control}
      getFieldState={form.getFieldState}
      getValues={form.getValues}
      handleSubmit={form.handleSubmit}
      register={form.register}
      resetField={form.resetField}
      setError={form.setError}
      setFocus={form.setFocus}
      setValue={form.setValue}
      trigger={form.trigger}
      unregister={form.unregister}
      watch={form.watch}
    >
      <form
        className="flex items-start flex-col mt-2 pb-10 max-md:pb-5 justify-between"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <CustomFormDropDown
          customForm={form}
          name="userId"
          labelText=""
          formItemStyle="flex w-[40%] flex-col max-md:mt-2 max-md:w-full"
          items={dropdownContent}
          placeholder="Odaberite korisnika"
        />
        <Button
          className="bg-[#28a745] py-3 px-3 text-white h-full mt-2"
          type="submit"
        >
          Dodaj
        </Button>
      </form>
    </Form>
  );
};

export default AddUserForDistributorForm;
