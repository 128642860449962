import { z } from 'zod';

export const createDistributorSchema = z.object({
  name: z.string().min(1, {
    message: 'Naziv je obavezno polje',
  }),
  email: z.string().email({
    message: 'Email nije validan',
  }),
  password: z.string().min(1, {
    message: 'Lozinka je obavezno polje',
  }),
  phoneNumber: z.string().min(1, {
    message: 'Telefon je obavezno polje',
  }),
  address: z.string().min(1, {
    message: 'Adresa je obavezno polje',
  }),
  place: z.string().min(1, {
    message: 'Mesto je obavezno polje',
  }),
  country: z.string().min(1, {
    message: 'Drzava je obavezno polje',
  }),
});

export const editDistributorSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1, {
    message: 'Naziv je obavezno polje',
  }),
  email: z.string().email({
    message: 'Email nije validan',
  }),
  phoneNumber: z.string().min(1, {
    message: 'Telefon je obavezno polje',
  }),
  address: z.string().min(1, {
    message: 'Adresa je obavezno polje',
  }),
  place: z.string().min(1, {
    message: 'Mesto je obavezno polje',
  }),
  country: z.string().min(1, {
    message: 'Drzava je obavezno polje',
  }),
});

export const userForDistributorSchema = z.object({
  userId: z.string().min(1, {
    message: 'Niste odabrali korisnika',
  }),
  distributorId: z.string().min(1, {
    message: 'Nije zadat distributer',
  }),
});
