import { Distributor } from '@/redux/distributors/distributors.types.ts';
import { RootState } from '@/redux/store.ts';
import { FetchUser } from '@/redux/users/users.types.ts';

export const getDistributors = (state: RootState): Distributor[] =>
  state.reducer.distributor.distributors || [];

export const getTotalNumberOfDistributors = (state: RootState): number =>
  state.reducer.distributor.totalNumberOfDistributors || 0;

export const getFilteredNumberOfDistributors = (state: RootState): number =>
  state.reducer.distributor.filteredNumberOfDistributors || 0;

export const getUsersForDistributor = (state: RootState): FetchUser[] =>
  state.reducer.distributor.usersForSelectedDistributor || [];

export const getTotalNumberOfUsersForDistributors = (
  state: RootState
): number => state.reducer.distributor.totalNumberOfUsersForDistributor || 0;

export const getFilteredNumberOfUsersForDistributors = (
  state: RootState
): number => state.reducer.distributor.filteredNumberOfUsersForDistributor || 0;

export const getDistributor = (state: RootState): Distributor =>
  state.reducer.distributor.distributor;
