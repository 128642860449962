import { z } from 'zod';

export const createFERTDeviceSchema = z.object({
  parcelId: z.string().min(1, {
    message: 'Id parcele nije zadat',
  }),
  name: z.string().min(1, {
    message: 'Naziv uredjaja je obavezno polje',
  }),
  color: z.string().min(1, {
    message: 'Boja uredjaja je obavezno polje',
  }),
  communicationModule: z.string().min(1, {
    message: 'Niste odabrali komunikacioni modul',
  }),
  zonesForDevice: z
    .string()
    .array()
    .refine((val) => val.length <= 14, {
      message: 'FERT uredjaj ne može upravljati više od 14 zona',
    }),
  pHFactorK: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'pH faktor K mora biti broj',
  }),
  pHFactorM: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'ph faktor M mora biti broj',
  }),
  ecFactorK: z.string().refine((val) => !Number.isNaN(parseInt(val, 10)), {
    message: 'Kalibracioni koeficijent K za EC',
  }),
  supplementOne: z.string(),
  supplementTwo: z.string(),
  supplementThree: z.string(),
  supplementFour: z.string(),
  supplementFive: z.string(),
  supplementMixer: z.string(),
  ecSensor: z.string(),
  flowMeter: z.string(),
  pHWater: z.string(),
  notificationForWrongFlowMeter: z.string(),
  pressureSensor: z.string(),
  waterLevelSensor: z.string(),
});

export const createStartDeviceSchema = z.object({
  parcelId: z.string().min(1, {
    message: 'Id parcele nije zadat',
  }),
  name: z.string().min(1, {
    message: 'Naziv uredjaja je obavezno polje',
  }),
  color: z.string().min(1, {
    message: 'Boja uredjaja je obavezno polje',
  }),
  zonesForDevice: z
    .string()
    .array()
    .refine((val) => val.length <= 8, {
      message: 'START uredjaj ne može upravljati više od 8 zona',
    }),
  humidityOne: z.string(),
  humidityTwo: z.string(),
  supplementMixer: z.string(),
  notificationForWrongFlowMeter: z.string(),
  pressureSensor: z.string(),
  flowMeter: z.string(),
});

export const createSensDeviceSchema = z.object({
  parcelId: z.string().min(1, {
    message: 'Id parcele nije zadat',
  }),
  name: z.string().min(1, {
    message: 'Naziv uredjaja je obavezno polje',
  }),
  color: z.string().min(1, {
    message: 'Boja uredjaja je obavezno polje',
  }),
  sensSensorType: z.string().optional(),
  zonesForDevice: z
    .string()
    .array()
    .refine((val) => val.length <= 4, {
      message: 'SENS uredjaj ne može upravljati više od 4 zone',
    }),
  standbyModeTime: z
    .string()
    .refine((val) => !Number.isNaN(parseInt(val, 10)), {
      message: 'pH faktor K mora biti broj',
    }),
  humidityOne: z.string(),
  humidityTwo: z.string(),
  flowMeter: z.string(),
  soilTemperature: z.string(),
  soilSalinity: z.string(),
  soilPH: z.string(),
  airTemperature: z.string(),
  airHumidity: z.string(),
  windDirection: z.string(),
  windSpeed: z.string(),
  solarRadiation: z.string(),
  amountOfPrecipitation: z.string(),
  batteryVoltage: z.string(),
  notificationForWrongFlowMeter: z.string(),
  pressureSensor: z.string(),
  batteryType: z.string().min(1, {
    message: 'Niste odabrali tip napajanja',
  }),
});

export const editStartDeviceSchema = createStartDeviceSchema.extend({
  id: z.string().min(1),
});

export const editFERTDeviceSchema = createFERTDeviceSchema.extend({
  id: z.string().min(1),
});

export const editSensDeviceSchema = createSensDeviceSchema.extend({
  id: z.string().min(1),
});

export const selectParcelForUserSchema = z.object({
  parcelId: z.string().min(1, {
    message: 'Parcela nije odabrana',
  }),
});

export const patchFERTSchema = z.object({
  id: z.string().min(1),
  rev: z.string(),
  serialNumber: z.string(),
  did: z.string(),
  mainPumpRelay24: z.boolean(),
  mixerRelay24: z.boolean(),
  communicationModule: z.string(),
  supplementOne: z.boolean(),
  supplementTwo: z.boolean(),
  supplementThree: z.boolean(),
  supplementFour: z.boolean(),
  supplementFive: z.boolean(),
  pHControl: z.boolean(),
  ecControl: z.boolean(),
  flowProportional: z.boolean(),
  fixedPumpPowerMod2: z.boolean(),
  pHWater: z.boolean(),
  flowMeterType: z.string(),
  irrigationByTime: z.boolean(),
  irrigationByVolume: z.boolean(),
  ecSensor: z.boolean(),
  waterLevelSensor: z.boolean(),
  commentOnEdit: z.string(),
});

export const patchSTARTSchema = z.object({
  id: z.string().min(1),
  rev: z.string(),
  did: z.string(),
  serialNumber: z.string(),
  mainPumpRelay24: z.boolean(),
  mixerRelay24: z.boolean(),
  flowMeterType: z.string(),
  irrigationByTime: z.boolean(),
  irrigationByVolume: z.boolean(),
  humidityOne: z.boolean(),
  humidityTwo: z.boolean(),
  commentOnEdit: z.string(),
});

export const patchSENSSchema = z.object({
  id: z.string().min(1),
  rev: z.string(),
  did: z.string(),
  serialNumber: z.string(),
  sentekTH30: z.boolean(),
  sentekTHS30: z.boolean(),
  sentekTH60: z.boolean(),
  sentekTHS60: z.boolean(),
  sentekTH90: z.boolean(),
  sentekTHS90: z.boolean(),
  dekagonHS1: z.boolean(),
  dekagonHS2: z.boolean(),
  soilPH: z.boolean(),
  batteryType: z.string(),
  airTempAndHumidType: z.string(),
  windSpeed: z.boolean(),
  windDirection: z.boolean(),
  amountOfPrecipitation: z.boolean(),
  solarRadiation: z.boolean(),
  flowMeterType: z.string(),
  irrigationByTime: z.boolean(),
  irrigationByVolume: z.boolean(),
  commentOnEdit: z.string(),
});
