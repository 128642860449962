import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ConfirmDeleteDialog from '@/components/layout/ConfirmDeleteDialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { deleteFruitType } from '@/redux/fruitType/fruitType.actions.ts';
import { getFruitTypes } from '@/redux/fruitType/fruitType.selectors.ts';
import {
  CreateFruitTypeResponse,
  FruitType,
} from '@/redux/fruitType/fruitType.types.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';

type FruitTypeElementProps = {
  fruitType: FruitType;
  index: number;
  length: number;
  onDeleteClick: (id: string) => void;
};

const FruitTypeElement: FC<FruitTypeElementProps> = ({
  fruitType,
  index,
  length,
  onDeleteClick,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onButtonClick = () => {
    setIsDialogOpen(true);
  };

  const onDeleteConfirmClick = () => {
    onDeleteClick(fruitType.id);
  };

  return (
    <>
      <li
        className={`${index !== length - 1 ? 'border-b-[1px] border-b-gray-200' : ''} px-5 py-3 flex flex-row justify-between w-full items-center`}
      >
        <p>{fruitType.name}</p>
        <Button
          className="bg-[#dc3545] py-3 px-5 text-white"
          onClick={onButtonClick}
        >
          Obriši
        </Button>
      </li>
      <ConfirmDeleteDialog
        headerText="Da li ste sigurni da želite da obrišete vrstu?"
        descriptionText="Ova akcija se ne može poništiti. Trajno ćete obrisati vrstu."
        onContinueClick={onDeleteConfirmClick}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  );
};

const FruitTypesSection: FC = () => {
  const fruitTypes = useAppSelector(getFruitTypes);
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: CreateFruitTypeResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDelete = async (id: string) => {
    // @ts-ignore
    const response = await dispatch(deleteFruitType({ id })).unwrap();

    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    toast.success('Vrsta uspešno obrisana');
  };

  if (!fruitTypes.length) {
    return (
      <div className="flex items-start flex-col mt-5 pb-10 w-full">
        <h2 className="text-xl font-bold ">Nema unetih vrsta</h2>
      </div>
    );
  }

  return (
    <div className="flex items-start flex-col mt-5 pb-10 w-full px-0">
      <ul className="w-full border-[1px] border-b-gray-200 rounded-lg">
        {fruitTypes.map((fruitType, index) => (
          <FruitTypeElement
            key={`fruit-type-${fruitType.name}-${index + 1}`}
            fruitType={fruitType}
            index={index}
            length={fruitTypes.length}
            onDeleteClick={onDelete}
          />
        ))}
      </ul>
    </div>
  );
};

export default FruitTypesSection;
