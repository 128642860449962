import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormRadioGroup from '@/components/controls/CustomFormRadioGroup.tsx';
import { itemsForRadioGroup } from '@/utils/device.util.ts';

type FERTDeviceSupplementFormProps = {
  form: UseFormReturn<any, any, any>;
};

const FERTDeviceSupplementForm: FC<FERTDeviceSupplementFormProps> = ({
  form,
}) => {
  return (
    <>
      <div className="flex flex-col font-bold">
        <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3">
          <CustomFormRadioGroup
            customForm={form}
            name="supplementOne"
            labelText="Prihrana 1"
            items={itemsForRadioGroup}
            formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
          />
          <CustomFormRadioGroup
            customForm={form}
            name="supplementTwo"
            labelText="Prihrana 2"
            items={itemsForRadioGroup}
            formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
          />
        </div>
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3">
        <CustomFormRadioGroup
          customForm={form}
          name="supplementThree"
          labelText="Prihrana 3"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="supplementFour"
          labelText="Prihrana 4"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3 pb-5">
        <CustomFormRadioGroup
          customForm={form}
          name="supplementFive"
          labelText="Prihrana 5"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="supplementMixer"
          labelText="Mešač Prihrane"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
    </>
  );
};

export default FERTDeviceSupplementForm;
