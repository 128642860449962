import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageHeader from '@/components/layout/PageHeader.tsx';
import EditParcelTabs from '@/components/layout/parcels/EditParcelTabs.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';
import { getOrganizations } from '@/redux/organizations/organizations.actions.ts';
import { GetOrganizationsResponse } from '@/redux/organizations/organizations.types.ts';
import { getParcelById } from '@/redux/parcels/parcels.actions.ts';
import {
  GetParcelByIdResponse,
  ParcelWithDevices,
} from '@/redux/parcels/parcels.types.ts';
import { getUsersForParcelCombobox } from '@/redux/users/users.actions.ts';
import { GetUserDropdownResponse } from '@/redux/users/users.types.ts';

const EditParcelPage: FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const router = useNavigate();
  const [parcelForEdit, setParcelForEdit] = useState<ParcelWithDevices>({
    id: '',
    name: '',
    numberOfZones: 0,
    maxBetweenTwoShifts: 60,
    place: '',
    country: '',
    weatherForecast: '',
    simCardNumber: '',
    boundaryForDryLand: 0,
    boundaryForWetlands: 0,
    coordinates: '',
    basicSensorsValues: {
      humiditySensor: false,
      secondHumiditySensor: false,
      supplementMixer: false,
      supplement: false,
      voltage: false,
      rawSensorValues: true,
    },
    additionalSensorsValues: {
      numberOfIrrigation: false,
      airHumidity: false,
      pumpSensor: false,
      numberOfZones: false,
      airTemperature: false,
      soilTemperatureSensor: false,
      soilSalinity: false,
      flowMeter: false,
      waterLevelSensor: false,
      pHSensor: false,
      flowMeterCapacity: 0,
      flowMeterFactorK: 0,
      flowMeterFactorM: 0,
      pHFactorK: 0,
      minHumidityForAutomaticIrrigation: 0,
      optimalHumidityForAutomaticIrrigation: 0,
      durationOfTheActiveValve: 0,
      timeBetweenValves: 0,
      moreSENSSensors: false,
    },
    fertDevices: [],
    sensDevices: [],
    startDevices: [],
    organizationId: '',
  });

  const handleErrorResponse = (
    response:
      | GetParcelByIdResponse
      | GetUserDropdownResponse
      | GetOrganizationsResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchParcel = async () => {
      // @ts-ignore
      const response = await dispatch(getParcelById(params.id)).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
        return;
      }

      setParcelForEdit(response.content);
    };

    const fetchOrganizationsForCombobox = async () => {
      const response = await dispatch(getOrganizations()).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchOrganizationsForCombobox();
    fetchParcel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    const fetchUsersForCombobox = async () => {
      // @ts-ignore
      const response = await dispatch(getUsersForParcelCombobox()).unwrap();

      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchUsersForCombobox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
          <EditParcelTabs parcelForEdit={parcelForEdit} />
        </div>
      </div>
    </div>
  );
};

export default EditParcelPage;
