import { BlockNoteView } from '@blocknote/shadcn';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { FormLabel } from '@/components/ui/form.tsx';

type CustomFormRichEditorProps = {
  name: string;
  form: UseFormReturn<any, any, any>;
  labelText?: string;
  labelStyle?: string;
  divStyle?: string;
  onRichTextEditorChange: (...event: any[]) => void;
  editorStyle?: string;
  editor: any;
};

const CustomFormRichEditor: FC<CustomFormRichEditorProps> = ({
  name,
  form,
  labelStyle,
  labelText,
  onRichTextEditorChange,
  divStyle,
  editorStyle,
  editor,
}) => {
  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field: { value, onChange } }) => (
        <div className={divStyle}>
          <FormLabel className={labelStyle}>{labelText}</FormLabel>
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
              }
            }}
          >
            <BlockNoteView
              editor={editor}
              theme="light"
              content={value}
              onChange={async () => onRichTextEditorChange(onChange)}
              className={editorStyle}
            />
          </div>
        </div>
      )}
    />
  );
};

export default CustomFormRichEditor;
