import { Eye, EyeOff } from 'lucide-react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Button } from '@/components/ui/button.tsx';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';

type CustomFormInputProps = {
  customForm: UseFormReturn<any, any, any>;
  name: string;
  labelText: string;
  type: string;
  formItemStyle?: string;
  labelStyle?: string;
  divStyle?: string;
  inputStyle?: string;
  placeholder?: string;
  isDisabled?: boolean;
  min?: string;
  togglePasswordVisibility?: () => void;
  showPassword?: boolean;
};

const CustomFormInput: FC<CustomFormInputProps> = ({
  customForm,
  name,
  labelStyle,
  labelText,
  divStyle,
  formItemStyle,
  type,
  inputStyle,
  placeholder,
  isDisabled,
  min,
  togglePasswordVisibility,
  showPassword,
}) => {
  return (
    <FormField
      control={customForm.control}
      name={name}
      render={({ field }) => (
        <FormItem className={formItemStyle}>
          <FormLabel className={labelStyle}>{labelText}</FormLabel>
          <div className={divStyle}>
            <FormControl className="w-full focus:outline-none">
              <div className="flex">
                <Input
                  {...field}
                  type={showPassword ? 'text' : type}
                  className={inputStyle}
                  placeholder={placeholder}
                  disabled={isDisabled}
                  min={min}
                />
                {togglePasswordVisibility &&
                  typeof showPassword === 'boolean' && (
                    <Button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="pl-1"
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </Button>
                  )}
              </div>
            </FormControl>
            <FormMessage className="mt-2" />
          </div>
        </FormItem>
      )}
    />
  );
};

export default CustomFormInput;
