import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { useAppDispatch, useAppSelector } from '@/redux/hooks.ts';
import { getUserStatistics } from '@/redux/statistics/statistics.actions.ts';
import {
  getNumberOfUsersByCountry,
  getUserUtilization,
} from '@/redux/statistics/statistics.selectors.ts';
import { GetUserStatisticsResponse } from '@/redux/statistics/statistics.types.ts';

const numberOfUsersChartConfig = {
  numberOfUsers: {
    label: 'Broj korisnika',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

const numberOfUtilizationChartConfig = {
  numberOfUtilization: {
    label: 'Broj korišćenja sistema',
    color: 'hsl(var(--chart-1))',
  },
} satisfies ChartConfig;

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { user, numberOfUtilization, lastUsage } = payload[0].payload;
    const formattedLastUsage = new Date(lastUsage).toLocaleString();

    return (
      <div className="custom-tooltip bg-white p-3 rounded-lg shadow-lg">
        <p className="">{user}</p>
        <p className="text-[#2b90d9]">{`Broj korišćenja sistema: ${numberOfUtilization}`}</p>
        <p className="text-[#2b90d9]">{`Poslednji pristup: ${formattedLastUsage}`}</p>
      </div>
    );
  }

  return null;
};

const UserStatistic: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();
  const numberOfUsersByCountry = useAppSelector(getNumberOfUsersByCountry);
  const userUtilization = useAppSelector(getUserUtilization);

  const handleErrorResponse = (response: GetUserStatisticsResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchUsersStatistics = async () => {
      // @ts-ignore
      const response = await dispatch(getUserStatistics()).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchUsersStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle>Korisnička statistika</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-row gap-5 max-md:flex-col">
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Broj aktivnih korisnika po državama</CardTitle>
              </CardHeader>
              <CardContent>
                <ChartContainer config={numberOfUsersChartConfig}>
                  <BarChart accessibilityLayer data={numberOfUsersByCountry}>
                    <CartesianGrid vertical={false} />
                    <YAxis
                      type="number"
                      dataKey="numberOfUsers"
                      hide={false}
                      axisLine={false}
                    />
                    <XAxis
                      dataKey="country"
                      tickLine={false}
                      tickMargin={10}
                      axisLine={false}
                    />
                    <ChartTooltip
                      cursor={false}
                      content={<ChartTooltipContent hideLabel />}
                    />
                    <Bar dataKey="numberOfUsers" fill="#2b90d9" radius={8} />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </Card>
          </div>
          <div className="flex w-[50%] max-md:w-full">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>
                  Korišćenje sistema u poslednjih mesec dana
                </CardTitle>
              </CardHeader>
              <CardContent>
                <ChartContainer config={numberOfUtilizationChartConfig}>
                  <BarChart layout="vertical" data={userUtilization}>
                    <CartesianGrid horizontal={false} />
                    <XAxis
                      type="number"
                      dataKey="numberOfUtilization"
                      hide={false}
                      axisLine={false}
                    />
                    <YAxis
                      type="category"
                      dataKey="user"
                      tickLine={false}
                      axisLine={false}
                      width={100}
                    />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Bar
                      dataKey="numberOfUtilization"
                      fill="#2b90d9"
                      radius={5}
                    />
                  </BarChart>
                </ChartContainer>
              </CardContent>
            </Card>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default UserStatistic;
