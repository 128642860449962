/* eslint-disable */
export const countries = [
  'Avganistan',
  'Albanija',
  'Alžir',
  'Andora',
  'Angola',
  'Angvila',
  'Antigva i Barbuda',
  'Argentina',
  'Jermenija',
  'Aruba',
  'Australija',
  'Austrija',
  'Azerbejdžan',
  'Bahami',
  'Bahrein',
  'Bangladeš',
  'Barbados',
  'Belorusija',
  'Belgija',
  'Beliz',
  'Benin',
  'Bermudi',
  'Butan',
  'Bolivija',
  'Bosna i Hercegovina',
  'Bocvana',
  'Britanska teritorija Indijskog okeana',
  'Brunej',
  'Bugarska',
  'Burkina Faso',
  'Mjanmar',
  'Burundi',
  'Kambodža',
  'Kamerun',
  'Kanada',
  'Zelenortska Ostrva',
  'Kajmanska Ostrva',
  'Centralnoafrička Republika',
  'Čad',
  'Božićno Ostrvo',
  'Kokosova (Kilingova) Ostrva',
  'Kina',
  'Kolumbija',
  'Komori',
  'Hrvatska',
  'Kuba',
  'Kipar',
  'Češka',
  'Danska',
  'Džibuti',
  'Dominikanska Republika',
  'Istočni Timor',
  'Tokelau',
  'Ostrva Turks i Kajkos',
  'Ekvador',
  'Egipat',
  'Salvador',
  'Ekvatorijalna Gvineja',
  'Eritreja',
  'Estonija',
  'Etiopija',
  'Farska Ostrva',
  'Foklandska Ostrva',
  'Fidži',
  'Finska',
  'Francuska',
  'Francuska Polinezija',
  'Gruzija',
  'Gibraltar',
  'Nemačka',
  'Gana',
  'Grčka',
  'Grenland',
  'Gvadelup',
  'Grenada',
  'Gernzi',
  'Gvatemala',
  'Guam',
  'Gvineja',
  'Gvineja-Bisao',
  'Gvajana',
  'Haiti',
  'Herd i Mekdonald Ostrva',
  'Honduras',
  'Hongkong',
  'Mađarska',
  'Island',
  'Indija',
  'Indonezija',
  'Iran',
  'Irak',
  'Irska',
  'Ostrvo Man',
  'Izrael',
  'Italija',
  'Obala Slonovače',
  'Jamajka',
  'Džersi',
  'Kazahstan',
  'Kenija',
  'Kiribati',
  'Kosovo',
  'Kirgistan',
  'Laos',
  'Letonija',
  'Liban',
  'Lesoto',
  'Liberija',
  'Libija',
  'Luksemburg',
  'Severna Makedonija',
  'Madagaskar',
  'Maršalska Ostrva',
  'Makao',
  'Malavi',
  'Malezija',
  'Maldivi',
  'Mali',
  'Malta',
  'Martinik',
  'Mauritanija',
  'Mauricijus',
  'Majot',
  'Meksiko',
  'Moldavija',
  'Mongolija',
  'Crna Gora',
  'Monako',
  'Maroko',
  'Mozambik',
  'Namibija',
  'Nauru',
  'Nepal',
  'Holandija',
  'Nova Kaledonija',
  'Nikaragva',
  'Niger',
  'Nigerija',
  'Norveška',
  'Nijue',
  'Norfolška Ostrva',
  'Severna Marijanska Ostrva',
  'Pakistan',
  'Pitkern',
  'Panama',
  'Papua Nova Gvineja',
  'Paragvaj',
  'Peru',
  'Filipini',
  'Poljska',
  'Portugal',
  'Portoriko',
  'Južna Koreja',
  'Litvanija',
  'Katar',
  'Rumunija',
  'Rusija',
  'Ruanda',
  'Reunion',
  'Samoa',
  'Sveti Lucija',
  'San Marino',
  'Sveti Pjer i Mikelon',
  'Saudijska Arabija',
  'Senegal',
  'Južna Džordžija i Južna Sendvička Ostrva',
  'Sao Tome i Principe',
  'Sijera Leone',
  'Srbija',
  'Sejšeli',
  'Singapur',
  'Slovačka',
  'Slovenija',
  'Solomonska Ostrva',
  'Somalija',
  'Južna Afrika',
  'Španija',
  'Šri Lanka',
  'Sudan',
  'Svazilend',
  'Švajcarska',
  'Sirija',
  'Tajvan',
  'Tanzanija',
  'Tajland',
  'Togo',
  'Tunis',
  'Turska',
  'Turkmenistan',
  'Uganda',
  'Ukrajina',
  'Ujedinjeni Arapski Emirati',
  'Ujedinjeno Kraljevstvo',
  'Sjedinjene Američke Države',
  'Urugvaj',
  'Uzbekistan',
  'Vanuatu',
  'Venecuela',
  'Vijetnam',
  'Jemen',
  'Zambija',
  'Zimbabve',
];

export const cities = [
  'Beograd',
  'Novi Sad',
  'Niš',
  'Kragujevac',
  'Subotica',
  'Zrenjanin',
  'Pančevo',
  'Čačak',
  'Kraljevo',
  'Smederevo',
  'Leskovac',
  'Užice',
  'Valjevo',
  'Kruševac',
  'Vranje',
  'Šabac',
  'Sombor',
  'Pirot',
  'Zaječar',
  'Požarevac',
  'Bor',
  'Sremska Mitrovica',
  'Prokuplje',
  'Loznica',
  'Kikinda',
  'Vršac',
  'Jagodina',
  'Paraćin',
  'Negotin',
  'Bačka Palanka',
  'Vrbas',
  'Trstenik',
  'Lazarevac',
  'Inđija',
  'Ruma',
  'Aranđelovac',
  'Senta',
  'Stara Pazova',
  'Ćuprija',
  'Aleksinac',
  'Bečej',
  'Apatin',
  'Knjaževac',
  'Nova Pazova',
  'Bajina Bašta',
  'Mladenovac',
  'Lučani',
  'Bogatić',
  'Žabalj',
];
