import {
  Home,
  Leaf,
  Map,
  UserCog2,
  Users2,
  Sheet,
  ChartNoAxesCombined,
  Mail,
} from 'lucide-react';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip.tsx';
import { resetBreadcrumbs } from '@/redux/breadcrumbs/breadcrumbs.slice.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type SidebarNavigationLinkProps = {
  text: string;
  icon: ReactNode;
  link: string;
};

const SidebarNavigationLink: FC<SidebarNavigationLinkProps> = ({
  text,
  link,
  icon,
}) => {
  const dispatch = useAppDispatch();

  const onLinkClick = () => {
    dispatch(resetBreadcrumbs());
  };

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          to={link}
          className="flex items-center flex-row justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground gap-2 mt-2"
          onClick={onLinkClick}
        >
          {icon}
          <p className="text-white text-base">{text}</p>
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right" className="hidden">
        {text}
      </TooltipContent>
    </Tooltip>
  );
};

const Sidebar: FC = () => {
  return (
    <TooltipProvider>
      <aside className="inset-y-0 left-0 z-10 hidden md:w-44 sm:w-40 flex-col border-r bg-[#2b90d9] sm:flex">
        <nav className="flex flex-col items-start gap-4 px-5 sm:py-5">
          <SidebarNavigationLink
            text="Početna"
            icon={<Home className="h-5 w-5 text-white" />}
            link="/"
          />
          <SidebarNavigationLink
            text="Korisnici"
            icon={<Users2 className="h-5 w-5 text-white" />}
            link="/users"
          />
          <SidebarNavigationLink
            text="Distributeri"
            icon={<UserCog2 className="h-5 w-5 text-white" />}
            link="/distributors"
          />
          <SidebarNavigationLink
            text="Parcele"
            icon={<Map className="h-5 w-5 text-white" />}
            link="/parcels"
          />
          <SidebarNavigationLink
            text="Kulture"
            icon={<Leaf className="h-5 w-5 text-white" />}
            link="/fruit-types"
          />
          <SidebarNavigationLink
            text="Isporuka"
            icon={<Sheet className="h-5 w-5 text-white" />}
            link="/delivery"
          />
          <SidebarNavigationLink
            text="Statistika"
            icon={<ChartNoAxesCombined className="h-5 w-5 text-white" />}
            link="/statistics"
          />
          <SidebarNavigationLink
            text="DID"
            icon={<Sheet className="h-5 w-5 text-white" />}
            link="/did"
          />
          <SidebarNavigationLink
            text="Mass Email"
            icon={<Mail className="h-5 w-5 text-white" />}
            link="/mass-mail"
          />
        </nav>
      </aside>
    </TooltipProvider>
  );
};

export default Sidebar;
