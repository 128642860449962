import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import CustomFormDropDown, {
  DropDownItems,
} from '@/components/controls/CustomFormDropDown.tsx';
import { Button } from '@/components/ui/button.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Form } from '@/components/ui/form.tsx';
import { useAppSelector } from '@/redux/hooks.ts';
import { getParcelIdsForUser } from '@/redux/users/user.selectors.ts';
import { selectParcelForUserSchema } from '@/zod/device.types.zod.ts';

type SelectUserParcelDialogProps = {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: ((prevState: boolean) => boolean) | boolean) => void;
};

type SelectParcelFormData = {
  parcelId: string;
};

const SelectUserParcelDialog: FC<SelectUserParcelDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
}) => {
  const parcelsForUser = useAppSelector(getParcelIdsForUser);
  const dropdownContent: DropDownItems[] = parcelsForUser.map((parcel) => {
    return {
      value: parcel.id,
      text: parcel.name,
    };
  });
  const selectParcelForm = useForm({
    resolver: zodResolver(selectParcelForUserSchema),
    defaultValues: {
      parcelId: '',
    },
  });
  const router = useNavigate();

  const onSubmit = async (data: SelectParcelFormData) => {
    router(`/parcels/edit/${data.parcelId}`);
  };

  useEffect(() => {
    if (parcelsForUser.length) {
      selectParcelForm.setValue('parcelId', parcelsForUser[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parcelsForUser]);

  return (
    <Dialog open={isDialogOpen} onOpenChange={() => setIsDialogOpen(false)}>
      <DialogContent className="sm:min-w-[600px] pb-10 rounded-lg">
        <DialogHeader>
          <DialogTitle>Odabrani korisnik ima više parcela</DialogTitle>
          <DialogDescription>
            Odaberite parcelu koju hoćete da pregledate
          </DialogDescription>
        </DialogHeader>
        <Form
          reset={selectParcelForm.reset}
          formState={selectParcelForm.formState}
          clearErrors={selectParcelForm.clearErrors}
          control={selectParcelForm.control}
          getFieldState={selectParcelForm.getFieldState}
          getValues={selectParcelForm.getValues}
          handleSubmit={selectParcelForm.handleSubmit}
          register={selectParcelForm.register}
          resetField={selectParcelForm.resetField}
          setError={selectParcelForm.setError}
          setFocus={selectParcelForm.setFocus}
          setValue={selectParcelForm.setValue}
          trigger={selectParcelForm.trigger}
          unregister={selectParcelForm.unregister}
          watch={selectParcelForm.watch}
        >
          <form
            className="grid gap-4 py-4"
            onSubmit={selectParcelForm.handleSubmit(onSubmit)}
          >
            <CustomFormDropDown
              customForm={selectParcelForm}
              name="parcelId"
              labelText="Izaberite parcelu"
              items={dropdownContent}
            />
            <DialogFooter className="flex max-sm:items-center">
              <Button
                type="submit"
                className="mt-5 bg-[#2b90d9] text-white px-2 py-3 items-center text-center"
              >
                Odaberi parcelu
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default SelectUserParcelDialog;
