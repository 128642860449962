import { FC } from 'react';

import FERTDevicesTable from '@/components/layout/delivery/FERTDevicesTable.tsx';
import SENSDevicesTable from '@/components/layout/delivery/SENSDevicesTable.tsx';
import STARTDevicesTable from '@/components/layout/delivery/STARTDevicesTable.tsx';
import {
  getDevicesRevisions,
  getUserDevices,
} from '@/redux/device/devices.selectors.ts';
import { DeviceRevision, DeviceType } from '@/redux/device/devices.types.ts';
import { useAppSelector } from '@/redux/hooks.ts';

const compareByRevision = (a: DeviceRevision, b: DeviceRevision) => {
  return a.revision.localeCompare(b.revision);
};

const DeliveryPageDevicesTables: FC = () => {
  const userDevices = useAppSelector(getUserDevices);
  const deviceRevisions = useAppSelector(getDevicesRevisions);
  const revisionsFERT = deviceRevisions
    .filter((rev) => rev.deviceType === DeviceType.FERT)
    .sort(compareByRevision);
  const revisionsSTART = deviceRevisions
    .filter((rev) => rev.deviceType === DeviceType.START)
    .sort(compareByRevision);
  const revisionsSENS = deviceRevisions
    .filter((rev) => rev.deviceType === DeviceType.SENS)
    .sort(compareByRevision);

  return (
    <div className="flex flex-col gap-5 overflow-x-auto">
      {userDevices?.fertDevices.length ? (
        <FERTDevicesTable
          userFERTDevices={userDevices?.fertDevices}
          revisionsFERT={revisionsFERT}
        />
      ) : null}
      {userDevices?.startDevices.length ? (
        <STARTDevicesTable
          userSTARTDevices={userDevices.startDevices}
          revisionsSTART={revisionsSTART}
        />
      ) : null}
      {userDevices?.sensDevices.length ? (
        <SENSDevicesTable
          userSENSDevices={userDevices.sensDevices}
          revisionsSENS={revisionsSENS}
        />
      ) : null}
    </div>
  );
};

export default DeliveryPageDevicesTables;
