import { RootState } from '@/redux/store.ts';
import {
  FetchUser,
  ParcelForUser,
  UserForDropdown,
  UsersCountries,
  UsersPlaces,
  UsersYearsOfCreation,
} from '@/redux/users/users.types.ts';

export const getUsers = (state: RootState): FetchUser[] =>
  state.reducer.user.users || [];

export const getTotalNumberOfUsers = (state: RootState): number =>
  state.reducer.user.totalNumberOfUsers || 0;

export const getFilteredNumberOfUsers = (state: RootState): number =>
  state.reducer.user.filteredNumberOfUsers || 0;

export const getDropdownUsers = (state: RootState): UserForDropdown[] =>
  state.reducer.user.usersForDropdown || [];

export const getParcelIdsForUser = (state: RootState): ParcelForUser[] =>
  state.reducer.user.parcelsForUser || [];

export const getCountriesForUsers = (state: RootState): UsersCountries[] =>
  state.reducer.user.usersCountries || [];

export const getPlacesForUsers = (state: RootState): UsersPlaces[] =>
  state.reducer.user.usersPlaces || [];

export const getYearsOfCreationForUsers = (
  state: RootState
): UsersYearsOfCreation[] => state.reducer.user.usersYearsOfCreation || [];
