import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';

import CustomFormRadioGroup from '@/components/controls/CustomFormRadioGroup.tsx';
import { itemsForRadioGroup } from '@/utils/device.util.ts';

type StartDeviceHumidityFormProps = {
  form: UseFormReturn<any, any, any>;
};

const StartDeviceHumidityForm: FC<StartDeviceHumidityFormProps> = ({
  form,
}) => {
  return (
    <div className="flex flex-col font-bold">
      <div className="w-full flex flex-row max-md:flex-col gap-2 mt-3 pb-3">
        <CustomFormRadioGroup
          customForm={form}
          name="humidityOne"
          labelText="Senzor vlažnosti zemljišta 1"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
        <CustomFormRadioGroup
          customForm={form}
          name="humidityTwo"
          labelText="Senzor vlažnosti zemljišta 2"
          items={itemsForRadioGroup}
          formItemStyle="border-[1px] border-gray-200 px-2 w-[50%] max-md:w-full"
        />
      </div>
    </div>
  );
};

export default StartDeviceHumidityForm;
