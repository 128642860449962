import debounce from 'lodash/debounce';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AddNewIcon from '@/components/controls/AddNewIcon.tsx';
import DistributorsTableSection from '@/components/layout/distributors/DistributorsTableSection.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import {
  deleteDistributor,
  getFilteredDistributors,
} from '@/redux/distributors/distributors.actions.ts';
import {
  CreateDistributorResponse,
  GetDistributorsResponse,
} from '@/redux/distributors/distributors.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

type FilterObject = {
  realPage: number;
  realRowsPerPage: number;
  realSortColumn: string;
  realSortAsc: boolean;
  realInputText: string;
};

const DistributorsPage: FC = () => {
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortColumn, setSortColumn] = useState<string>('');
  const [isSortAsc, setIsSortAsc] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>('');

  const createQueryParams = (filterObject: FilterObject) => {
    const {
      realPage,
      realRowsPerPage,
      realSortColumn,
      realSortAsc,
      realInputText,
    } = filterObject;
    return `?page=${realPage}&perPage=${realRowsPerPage}${realSortColumn ? `&sortColumn=${realSortColumn}&sortDirection=${realSortAsc ? 'ASC' : 'DESC'}` : ''}${realInputText ? `&inputText=${realInputText}` : ''}`;
  };

  const callFetchDistributors = async (queryParams: string) => {
    // @ts-ignore
    return dispatch(getFilteredDistributors(queryParams)).unwrap();
  };

  const handleErrorResponse = (
    response: GetDistributorsResponse | CreateDistributorResponse
  ) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  const onDebounceCall = async (value: string) => {
    setInputText(value);
    setPage(0);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: value,
    };
    const queryParams = createQueryParams(filterObject);
    await callFetchDistributors(queryParams);
  };

  const debouncedChangeHandler = debounce((value: string) => {
    onDebounceCall(value);
  }, 500);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    debouncedChangeHandler(value);
  };

  const onColumnClick = async (columnName: string) => {
    if (columnName === sortColumn) {
      setIsSortAsc(!isSortAsc);
      const filterObject: FilterObject = {
        realPage: page,
        realRowsPerPage: rowsPerPage,
        realSortColumn: sortColumn,
        realSortAsc: !isSortAsc,
        realInputText: inputText,
      };
      const queryParams = createQueryParams(filterObject);
      await callFetchDistributors(queryParams);
      return;
    }

    setSortColumn(columnName);
    setIsSortAsc(true);
    const filterObject: FilterObject = {
      realPage: page,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: true,
      realInputText: inputText,
    };
    const queryParams = createQueryParams(filterObject);
    await callFetchDistributors(queryParams);
  };

  const onPageChangeClick = async (selectedPage: number) => {
    const filterObject: FilterObject = {
      realPage: selectedPage,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
    };
    const queryParams = createQueryParams(filterObject);
    setPage(selectedPage);
    await callFetchDistributors(queryParams);
  };

  const fetchDistributors = async () => {
    const filterObject: FilterObject = {
      realPage: page,
      realRowsPerPage: rowsPerPage,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
    };
    const queryParams = createQueryParams(filterObject);
    const response = await callFetchDistributors(queryParams);
    if (!response.success) {
      handleErrorResponse(response);
    }
  };

  const onDeleteDistributorClick = async (distributorId: string) => {
    // @ts-ignore
    const response = await dispatch(
      deleteDistributor({ distributorId })
    ).unwrap();
    if (!response.success) {
      handleErrorResponse(response);
      return;
    }

    await fetchDistributors();
    toast.success('Distributer je uspešno izbrisan');
  };

  const onRowsPerPageChange = async (value: number) => {
    setPage(0);
    setRowsPerPage(value);
    const filterObject: FilterObject = {
      realPage: 0,
      realRowsPerPage: value,
      realSortColumn: sortColumn,
      realSortAsc: isSortAsc,
      realInputText: inputText,
    };

    const queryParams = createQueryParams(filterObject);
    await callFetchDistributors(queryParams);
  };

  useEffect(() => {
    fetchDistributors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput handleChange={handleChange} />
        <div className="grid items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-4">
          <div className="flex items-center gap-2">
            <AddNewIcon
              link="/distributors/create"
              text="Dodajte distributera"
            />
          </div>
          <DistributorsTableSection
            page={page}
            rowsPerPage={rowsPerPage}
            onColumnClick={onColumnClick}
            onPageChangeClick={onPageChangeClick}
            onDistributorDelete={onDeleteDistributorClick}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DistributorsPage;
