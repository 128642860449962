import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import EditDistributorInfoForm from '@/components/forms/EditDistributorInfoForm.tsx';
import EditDistributorsUserSection from '@/components/layout/distributors/EditDistributorsUserSection.tsx';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs.tsx';

export type EditDistributorFormData = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  place: string;
  country: string;
};

enum EditDistributorTab {
  INFO = 'info',
  USERS = 'users',
}

const EditDistributorTabs: FC = () => {
  const [distributorTab, setDistributorTab] = useState<string>(
    EditDistributorTab.INFO
  );
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.get('tab')) {
      setDistributorTab(EditDistributorTab.USERS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs value={distributorTab}>
      <TabsList>
        <TabsTrigger
          value={EditDistributorTab.INFO}
          className="data-[state=active]:bg-[#2a90d9] data-[state=active]:text-white"
          onClick={() => setDistributorTab(EditDistributorTab.INFO)}
        >
          Info
        </TabsTrigger>
        <TabsTrigger
          value={EditDistributorTab.USERS}
          className="data-[state=active]:bg-[#2a90d9] data-[state=active]:text-white"
          onClick={() => setDistributorTab(EditDistributorTab.USERS)}
        >
          Korisnici
        </TabsTrigger>
      </TabsList>
      <TabsContent value={EditDistributorTab.INFO}>
        <EditDistributorInfoForm />
      </TabsContent>
      <TabsContent value={EditDistributorTab.USERS}>
        <EditDistributorsUserSection />
      </TabsContent>
    </Tabs>
  );
};

export default EditDistributorTabs;
