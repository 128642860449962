import { ReactNode } from 'react';

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination.tsx';

const createSinglePaginationElement = () => {
  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationLink isActive>1</PaginationLink>
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

const createClickablePaginationElement = (
  index: number,
  page: number,
  onPageChangeClick: (selectedPage: number) => void
) => {
  return (
    <PaginationItem key={`pagination-page-${index + 1}`}>
      <PaginationLink
        onClick={() => onPageChangeClick(index)}
        isActive={index === page}
      >
        {index + 1}
      </PaginationLink>
    </PaginationItem>
  );
};

const createPaginationEllipsis = (key: string) => {
  return (
    <PaginationItem key={key}>
      <PaginationEllipsis />
    </PaginationItem>
  );
};

const createPaginationPreviousLink = (
  page: number,
  onPageChangeClick: (selectedPage: number) => void
) => {
  return (
    <PaginationItem key="pagination-previous">
      <PaginationPrevious onClick={() => onPageChangeClick(page)} />
    </PaginationItem>
  );
};

const createPaginationNextLink = (
  page: number,
  onPageChangeClick: (selectedPage: number) => void
) => {
  return (
    <PaginationItem key="pagination-next">
      <PaginationNext onClick={() => onPageChangeClick(page)} />
    </PaginationItem>
  );
};

const createElementsWhenNotFirstPage = (
  page: number,
  numberOfPages: number,
  onPageChangeClick: (selectedPage: number) => void
) => {
  const paginationLinks: ReactNode[] = [];

  paginationLinks.push(
    createPaginationPreviousLink(page - 1, onPageChangeClick)
  );

  if (page - 1 > 0) {
    paginationLinks.push(createPaginationEllipsis('pagination-ellipsis-prev'));
  }

  for (let i = page - 1; i <= page + 1; i += 1) {
    if (i + 1 <= numberOfPages)
      paginationLinks.push(
        createClickablePaginationElement(i, page, onPageChangeClick)
      );
  }

  if (page + 2 < numberOfPages) {
    paginationLinks.push(createPaginationEllipsis('pagination-ellipsis-next'));
  }

  if (page + 1 < numberOfPages) {
    paginationLinks.push(createPaginationNextLink(page + 1, onPageChangeClick));
  }

  return (
    <Pagination>
      <PaginationContent>
        {paginationLinks.map((paginationLink) => paginationLink)}
      </PaginationContent>
    </Pagination>
  );
};

const createElementsWhenFirstPage = (
  page: number,
  numberOfPages: number,
  onPageChangeClick: (selectedPage: number) => void
) => {
  const paginationLinks: ReactNode[] = [];

  paginationLinks.push(
    createClickablePaginationElement(0, page, onPageChangeClick)
  );
  paginationLinks.push(
    createClickablePaginationElement(1, page, onPageChangeClick)
  );
  if (numberOfPages > 2) {
    paginationLinks.push(createPaginationEllipsis('pagination-ellipsis-next'));
  }
  paginationLinks.push(createPaginationNextLink(1, onPageChangeClick));

  return (
    <Pagination>
      <PaginationContent>
        {paginationLinks.map((paginationLink) => paginationLink)}
      </PaginationContent>
    </Pagination>
  );
};

export const createPaginationSection = (
  rowsPerPage: number,
  numberOfRecordsToShow: number,
  page: number,
  onPageChangeClick: (selectedPage: number) => void
) => {
  if (!numberOfRecordsToShow) {
    return null;
  }

  if (numberOfRecordsToShow <= rowsPerPage) {
    return createSinglePaginationElement();
  }

  const numberOfPages = Math.ceil(numberOfRecordsToShow / rowsPerPage);
  if (page > 0) {
    return createElementsWhenNotFirstPage(
      page,
      numberOfPages,
      onPageChangeClick
    );
  }

  return createElementsWhenFirstPage(page, numberOfPages, onPageChangeClick);
};
