import { FetchParcel } from '@/redux/parcels/parcels.types.ts';
import { RootState } from '@/redux/store.ts';
import { UsersCountries, UsersPlaces } from '@/redux/users/users.types.ts';

export const getParcels = (state: RootState): FetchParcel[] =>
  state.reducer.parcel.parcels || [];

export const getTotalNumberOfParcels = (state: RootState): number =>
  state.reducer.parcel.totalNumberOfParcels || 0;

export const getFilteredNumberOfParcels = (state: RootState): number =>
  state.reducer.parcel.filteredNumberOfParcels || 0;

export const getCountriesForParcels = (state: RootState): UsersCountries[] =>
  state.reducer.parcel.parcelsCountries || [];

export const getPlacesForParcels = (state: RootState): UsersPlaces[] =>
  state.reducer.parcel.parcelsPlaces || [];
