import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeliveryPageContent from '@/components/layout/delivery/DeliveryPageContent.tsx';
import PageHeader from '@/components/layout/PageHeader.tsx';
import Sidebar from '@/components/layout/Sidebar.tsx';
import { logoutState, setName } from '@/redux/auth/auth.slice.ts';
import { getOccupiedDidsWithoudDevice } from '@/redux/did/did.actions.ts';
import { AllDidsResponse } from '@/redux/did/did.types.ts';
import { useAppDispatch } from '@/redux/hooks.ts';

const DeliveryPage: FC = () => {
  const dispatch = useAppDispatch();
  const router = useNavigate();

  const handleErrorResponse = (response: AllDidsResponse) => {
    if (response.error.removeUser) {
      localStorage.removeItem('token');
      localStorage.removeItem('name');
      dispatch(logoutState());
      dispatch(setName(''));
      router('/login');
      return;
    }

    toast.error(response.error.message);
  };

  useEffect(() => {
    const fetchDids = async () => {
      const response = await dispatch(getOccupiedDidsWithoudDevice()).unwrap();
      if (!response.success) {
        handleErrorResponse(response);
      }
    };

    fetchDids();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-row bg-muted/40 overflow-x-auto">
      <Sidebar />
      <div className="flex flex-col sm:gap-4 sm:py-4 w-full">
        <PageHeader shouldShowInput={false} />
        <div className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 overflow-x-auto">
          <DeliveryPageContent />
        </div>
      </div>
    </div>
  );
};

export default DeliveryPage;
